import useFetch from './useFetch';

export function useTagResponses() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: `v1/responses/tag/`,
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useCreateOrder() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: 'v1/responses/orders/',
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useUpdateOrder() {
  const fetcher = useFetch();
  return ({ orderId, data }) =>
    fetcher({
      path: `v1/responses/orders/${orderId}/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useUpdateSentiment() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: `v1/responses/sentiment/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}
