import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { MATOMO_URL } from './constants';

const instance = createInstance({
  urlBase: MATOMO_URL,
  siteId: process.env.REACT_APP_MATOMO_SITE_ID,
});

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);
