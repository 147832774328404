import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, Row, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useCodeFrameThemes, useGroupThemes, useOmitTheme } from '../../../api/CodeFrames';
import { useCreateUpdateTheme, useDeleteTheme } from '../../../api/Themes';
import Loading from '../../../components/Loading';
import { ThemeEngineQueryKey, ThemeOrganizationContainer } from '../../../constants';
import useParams from '../../../hooks/useParams';
import { queryType, questionType } from '../../../types/index';
import CreateUpdateThemeGroupModal from '../CreateUpdateThemeGroupModal';
import DeleteWarningModal from './DeleteWarningModal';
import NewGroupContainer from './NewGroupContainer';
import OmitThemesContainer from './OmitThemesContainer';
import ThemeCard from './ThemeCard';
import ThemeGroupContainer from './ThemeGroupContainer';
import UngroupedThemesContainer from './UngroupedThemesContainer';

const OuterRow = styled(Row)`
  height: calc(100vh - 275px);
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const GroupsRow = styled(Row)`
  overflow-x: ${(props) => (props.$hasShadow ? 'hidden' : 'auto')};
  flex-flow: row;
  flex-grow: 1;
`;

const GroupContainerShadow = styled.div`
  height: 100%;
  width: 250px;
  background-color: #fafafa;
  margin-top: 40px;
`;

const GROUP_CONTAINER_WIDTH = 266;
const OUTSIDE_GROUP_ROW_WIDTH = 580;

const calcContainerShadowCount = (groupCount) => {
  // calculate width of theme group section
  const groupRowWidth = window.innerWidth - OUTSIDE_GROUP_ROW_WIDTH;
  // calcuate width of existing theme groups
  const existingGroupContainersWidth = groupCount * GROUP_CONTAINER_WIDTH;
  // if extra space return number of containers that can fit rounded up
  // otherwise return 0
  return existingGroupContainersWidth < groupRowWidth
    ? Math.ceil((groupRowWidth - existingGroupContainersWidth) / GROUP_CONTAINER_WIDTH)
    : 0;
};

function ThemeOrganization({ themes, question }) {
  const { codeFrameId } = useParams();
  const queryClient = useQueryClient();

  const [filteredThemes, setFilteredThemes] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);
  const [hasDismissedDeleteWarning, setHasDismissedDeleteWarning] = useState(false);
  const [containerMap, setContainerMap] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [containerShadowCount, setContainerShadowCount] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(undefined);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
  );

  window.addEventListener('resize', () => {
    setContainerShadowCount(
      calcContainerShadowCount(themes.data.filter((t) => t.is_parent).length),
    );
  });

  useEffect(() => {
    setContainerShadowCount(
      calcContainerShadowCount(themes.data.filter((t) => t.is_parent).length),
    );
  }, [themes]);

  const activeContainerId = useMemo(() => {
    const containerId = Object.keys(containerMap).find((k) =>
      containerMap[k].some((t) => t.id === activeId),
    );
    return parseInt(containerId, 10) || containerId;
  }, [activeId, containerMap]);

  const { isFetching: themesFetching } = useQuery(
    [ThemeEngineQueryKey.THEME_ORGANIZATION_THEMES, { codeFrameId }],
    useCodeFrameThemes(),
    {
      onSuccess: (data) => {
        /*
          structure themes in an object with containers as keys:
          {
            4: [ { id: 5, ...} ],
            6: [ { id: 7, ... } ],
            ungrouped: [ { id: 1, name: 'theme a', ...}, { id: 2, ...} ],
            omitted: [ { id: 3, ...} ],
            new: []
          }
        */
        setContainerMap(
          data.data.reduce(
            (acc, curr) => {
              if (!curr.is_parent) {
                let container;
                if (curr.is_omitted) {
                  container = ThemeOrganizationContainer.OMITTED;
                } else if (curr.parent_theme_id) {
                  container = curr.parent_theme_id;
                } else {
                  container = ThemeOrganizationContainer.UNGROUPED;
                }
                acc[container] = acc[container] ? [...acc[container], curr] : [curr];
              }
              return acc;
            },
            {
              [ThemeOrganizationContainer.UNGROUPED]: [],
              [ThemeOrganizationContainer.OMITTED]: [],
              [ThemeOrganizationContainer.NEW]: [],
            },
          ),
        );
      },
    },
  );

  useEffect(() => {
    setFilteredThemes(
      containerMap[ThemeOrganizationContainer.UNGROUPED]?.filter((t) =>
        t.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [containerMap, searchTerm]);

  const mutationError = () => {
    message.error(`Something went wrong grouping themes`);
    queryClient.invalidateQueries({
      queryKey: [ThemeEngineQueryKey.THEME_ORGANIZATION_THEMES, { codeFrameId }],
    });
    queryClient.invalidateQueries({
      queryKey: [ThemeEngineQueryKey.THEMES, { codeFrameId }],
    });
  };

  const omitTheme = useMutation({
    mutationFn: useOmitTheme(),
    onError: mutationError,
  });

  const groupThemes = useMutation({
    mutationFn: useGroupThemes(),
    onError: mutationError,
  });

  const createTheme = useMutation({
    mutationFn: useCreateUpdateTheme(),
    onError: mutationError,
  });

  const deleteTheme = useMutation({
    mutationFn: useDeleteTheme(),
    onError: mutationError,
  });

  // structure updated themes query to reflect change
  const getUpdatedThemes = (newGroupId) => {
    let updatedThemes = themes.data;
    if (overId === ThemeOrganizationContainer.UNGROUPED) {
      updatedThemes = updatedThemes.map((t) =>
        t.id === activeId ? { ...t, is_omitted: false, parent_theme_id: null } : t,
      );
    } else if (overId === ThemeOrganizationContainer.OMITTED) {
      updatedThemes = updatedThemes.map((t) =>
        t.id === activeId ? { ...t, is_omitted: true, parent_theme_id: null } : t,
      );
    } else if (overId === ThemeOrganizationContainer.NEW) {
      updatedThemes = [
        ...updatedThemes,
        {
          id: newGroupId,
          name: 'New Group',
          description: '',
          response_ids: [],
          is_parent: true,
          parent_theme_id: null,
          is_omitted: false,
          created_at: new Date().toISOString(),
        },
      ];
      updatedThemes = updatedThemes.map((t) =>
        t.id === activeId ? { ...t, is_omitted: false, parent_theme_id: newGroupId } : t,
      );
    } else {
      updatedThemes = updatedThemes.map((t) =>
        t.id === activeId ? { ...t, is_omitted: false, parent_theme_id: overId } : t,
      );
    }
    const childThemes = themes.data.filter((t) => t.parent_theme_id === activeContainerId);
    if (childThemes.length === 1) {
      updatedThemes = updatedThemes.filter((t) => t.id !== activeContainerId);
    }
    return updatedThemes;
  };

  const handleDragEnd = async () => {
    const activeChildThemes = themes.data.filter((t) => t.parent_theme_id === activeContainerId);

    // move theme to destination container
    setContainerMap((prev) => ({
      ...prev,
      [activeContainerId]: prev[activeContainerId].filter((t) => activeId !== t.id),
      [overId]: [...prev[overId], themes.data.find((t) => activeId === t.id)],
    }));

    let newGroupId;
    if (overId === ThemeOrganizationContainer.NEW) {
      const response = await createTheme.mutateAsync({
        data: {
          name: 'New Group',
          description: '',
          code_frame_id: codeFrameId,
        },
      });
      newGroupId = response.data.theme_id;
      // update containerMap with new group
      setContainerMap((prev) => ({
        ...prev,
        [newGroupId]: [themes.data.find((t) => t.id === activeId)],
        [ThemeOrganizationContainer.NEW]: [],
      }));
    }

    queryClient.setQueryData([ThemeEngineQueryKey.THEMES, { codeFrameId }], {
      ...themes,
      data: getUpdatedThemes(newGroupId),
    });

    // if group is being left empty, delete the group
    if (activeChildThemes.length === 1) {
      setContainerMap((prev) => {
        const { [activeContainerId]: remove, ...rest } = prev;
        return rest;
      });
      deleteTheme.mutate({
        themeId: activeContainerId,
      });
    }

    if (overId === ThemeOrganizationContainer.UNGROUPED) {
      if (activeContainerId === ThemeOrganizationContainer.OMITTED) {
        // if theme was previously omitted set omitted to false
        omitTheme.mutate({
          codeFrameId,
          themeId: activeId,
          data: { is_omitted: false },
        });
      } else if (activeChildThemes.length !== 1) {
        // if theme previously had a parent set parent to null
        const updatedChildIds = activeChildThemes.filter((c) => c.id !== activeId).map((c) => c.id);
        groupThemes.mutate({
          codeFrameId,
          data: {
            parent_theme_id: activeContainerId,
            child_theme_ids: updatedChildIds,
          },
        });
      }
    } else if (overId === ThemeOrganizationContainer.OMITTED) {
      omitTheme.mutate({
        codeFrameId,
        themeId: activeId,
        data: { is_omitted: true },
      });
    } else {
      // if theme is dragged to an existing group or a new group was created add theme to that group
      const childIds = newGroupId
        ? [activeId]
        : [...themes.data.filter((t) => t.parent_theme_id === overId).map((c) => c.id), activeId];
      groupThemes.mutate({
        codeFrameId,
        data: {
          parent_theme_id: newGroupId || overId,
          child_theme_ids: childIds,
        },
      });
    }
    setActiveId(null);
    setOverId(null);
  };

  const openGroupModal = (group) => {
    setSelectedGroup(group);
    setGroupModalVisible(true);
  };

  const createUpdateGroupFromModal = (groupId, childIds) => {
    let ungroupedThemes = containerMap[ThemeOrganizationContainer.UNGROUPED].filter(
      (t) => !childIds.includes(t.id),
    );
    if (selectedGroup) {
      const removedThemes = containerMap[groupId].filter((t) => !childIds.includes(t.id));
      ungroupedThemes = [...ungroupedThemes, ...removedThemes];
    }
    setContainerMap((prev) => ({
      ...prev,
      [ThemeOrganizationContainer.UNGROUPED]: ungroupedThemes,
      [ThemeOrganizationContainer.OMITTED]: containerMap[ThemeOrganizationContainer.OMITTED].filter(
        (t) => !childIds.includes(t.id),
      ),
      [groupId]: themes.data.filter((t) => childIds.includes(t.id)),
    }));
  };

  const deleteGroupFromModal = () => {
    const childThemes = containerMap[selectedGroup.id];
    setContainerMap((prev) => {
      const { [selectedGroup.id]: remove, ...rest } = prev;
      return {
        ...rest,
        [ThemeOrganizationContainer.UNGROUPED]: [
          ...containerMap[ThemeOrganizationContainer.UNGROUPED],
          ...childThemes,
        ],
      };
    });
  };

  if (themesFetching) {
    return <Loading />;
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => setActiveId(active.id)}
        onDragOver={({ over }) => setOverId(over?.id)}
        onDragEnd={() => {
          // take no action if theme is not dropped in a container
          // OR theme is dropped in its active container
          if (!overId || overId === activeContainerId) {
            setActiveId(null);
            setOverId(null);
            return;
          }
          const childThemes = themes.data.filter((t) => t.parent_theme_id === activeContainerId);
          // if theme is the only child of a theme group and user has not dismissed the warning modal
          if (childThemes.length === 1 && !hasDismissedDeleteWarning) {
            setIsDeleteModalOpen(true);
          } else {
            handleDragEnd();
          }
        }}
      >
        <OuterRow wrap={false}>
          <StyledCol flex="250px">
            <UngroupedThemesContainer
              themes={filteredThemes}
              responseCount={question.num_responses}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </StyledCol>
          <StyledCol flex="auto">
            <StyledDiv>
              <GroupsRow gutter={16} $hasShadow={containerShadowCount}>
                <NewGroupContainer openGroupModal={openGroupModal} />
                {themes.data
                  .filter((t) => t.is_parent)
                  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                  .map((theme) => (
                    <ThemeGroupContainer
                      key={theme.id}
                      group={theme}
                      childThemes={containerMap[theme.id]}
                      responseCount={question.num_responses}
                      openGroupModal={openGroupModal}
                    />
                  ))}
                {[...Array(containerShadowCount)].map((_, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col key={idx}>
                    <GroupContainerShadow />
                  </Col>
                ))}
              </GroupsRow>
              <OmitThemesContainer
                themes={containerMap[ThemeOrganizationContainer.OMITTED]}
                responseCount={question.num_responses}
              />
            </StyledDiv>
          </StyledCol>
        </OuterRow>
        <DragOverlay>
          {activeId ? (
            <ThemeCard
              theme={themes.data.find((t) => t.id === activeId)}
              responseCount={question.num_responses}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <DeleteWarningModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        setHasDismissedDeleteWarning={setHasDismissedDeleteWarning}
        handleDragEnd={handleDragEnd}
        groupName={themes.data.find((t) => t.id === activeContainerId)?.name}
      />
      <CreateUpdateThemeGroupModal
        themes={themes}
        visible={isGroupModalVisible}
        closeModal={() => {
          setGroupModalVisible(false);
          setSelectedGroup(undefined);
        }}
        selectedGroup={selectedGroup}
        codeFrameId={codeFrameId}
        onCreateUpdateGroup={createUpdateGroupFromModal}
        onDeleteGroup={deleteGroupFromModal}
      />
    </>
  );
}

ThemeOrganization.propTypes = {
  themes: queryType.isRequired,
  question: questionType.isRequired,
};

export default ThemeOrganization;
