import { RightOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { QCPhase } from '../../constants';
import { BadgeBlue, BadgeGray, BlueDaybreak6 } from '../../styles';

const phaseBadgeColors = {
  [QCPhase.PROCESSING]: [BadgeGray, BadgeGray, BadgeGray, BadgeGray],
  [QCPhase.READY]: [BadgeGray, BadgeGray, BadgeGray, BadgeGray],
  [QCPhase.REMAPPING]: [BadgeGray, BadgeGray, BadgeGray, BadgeGray],
  [QCPhase.REMAPPING_COMPLETE]: [BadgeBlue, BadgeGray, BadgeGray, BadgeGray],
  [QCPhase.COMPLETE]: [BadgeBlue, BadgeBlue, BadgeBlue, BadgeBlue],
};

function OpenEndedQuestionPhase({ currentPhase }) {
  return (
    <Space size={6}>
      {currentPhase}
      {phaseBadgeColors[currentPhase].map((color, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Badge color={color} key={idx} />
      ))}
      <RightOutlined style={{ color: BlueDaybreak6 }} />
    </Space>
  );
}

OpenEndedQuestionPhase.propTypes = {
  currentPhase: PropTypes.string.isRequired,
};

export default OpenEndedQuestionPhase;
