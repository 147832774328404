import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loading from '../components/Loading';

// only allows access to users that are not authenticated
function UnprotectedRoute() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
}

export default UnprotectedRoute;
