import { useDroppable } from '@dnd-kit/core';
import { Card, Collapse, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ThemeOrganizationContainer } from '../../../constants';
import { themeType } from '../../../types';
import DraggableTheme from './DraggableTheme';

const { Text } = Typography;
const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background: #fff;

  .ant-collapse-item .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`;

const StyledCard = styled(Card)`
  border-color: #d9d9d9;
  height: 103px;
  background-color: #fafafa;
  overflow: auto;
  display: flex;
  flex-grow: 1;

  > .ant-card-body {
    flex-grow: 1;
    display: flex;
    margin: 8px;
    padding: 0px;
    background-color: ${(props) => (props.$isOver ? '#f0f0f0' : '#fafafa')};
  }
`;

const Size16Text = styled(Text)`
  font-size: 16px;
`;

function OmitThemesContainer({ themes, responseCount }) {
  const { setNodeRef, isOver } = useDroppable({ id: ThemeOrganizationContainer.OMITTED });

  return (
    <StyledCollapse bordered={false} defaultActiveKey="1">
      <Panel key="1" header={<Size16Text>Omit from analysis</Size16Text>}>
        <StyledCard ref={setNodeRef} $isOver={isOver} data-cy="omit-container">
          <Space>
            {themes
              ?.sort(
                (a, b) =>
                  b.response_ids.length - a.response_ids.length || a.name.localeCompare(b.name),
              )
              .map((t) => (
                <DraggableTheme key={t.id} theme={t} responseCount={responseCount} />
              ))}
          </Space>
        </StyledCard>
      </Panel>
    </StyledCollapse>
  );
}

OmitThemesContainer.propTypes = {
  themes: PropTypes.arrayOf(themeType).isRequired,
  responseCount: PropTypes.number.isRequired,
};

export default OmitThemesContainer;
