import { useParams as useParamsBase } from 'react-router-dom';

export default function useParams() {
  const params = useParamsBase();

  // Convert params to numbers if possible, otherwise leave as strings
  Object.keys(params).forEach((p) => {
    params[p] = Number(params[p]) || params[p];
  });

  return params;
}
