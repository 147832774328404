import { useAuth0 } from '@auth0/auth0-react';
import { useMatomo as useMatomoBase } from '@jonkoops/matomo-tracker-react';
import { EVENT_METRICS } from '../constants';

export default function useMatomo() {
  const { trackEvent: trackEventMatomo, trackPageView: trackPageViewMatomo } = useMatomoBase();
  const { user } = useAuth0();

  const trackEvent = (event) =>
    trackEventMatomo({
      category: EVENT_METRICS,
      action: event,
      name: user.email,
    });

  const trackPageView = (page) =>
    trackPageViewMatomo({
      documentTitle: page,
      customDimensions: [{ user: user.email }],
    });

  return { trackEvent, trackPageView };
}
