import { HolderOutlined } from '@ant-design/icons';
import { Card, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  cursor: grab;
  border-left: none;
  border-right: none;
  border-top: none;

  .ant-card-head {
    padding-left: 0px;
  }

  .ant-card-head-title {
    white-space: break-spaces;
  }

  .ant-card-body {
    display: none;
  }

  &:active {
    cursor: grabbing;
  }
`;

const StyledHolderOutlined = styled(HolderOutlined)`
  color: grey;
  font-size: 16px;
`;

const ResponseOption = forwardRef(({ text, ...props }, ref) => {
  return (
    <StyledCard
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      size="small"
      title={
        <Space size="middle">
          <StyledHolderOutlined />
          <div>{text}</div>
        </Space>
      }
    />
  );
});

ResponseOption.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ResponseOption;
