import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useOrganization } from '../api/Onboarding';

export default function useSelfServeOrganization() {
  const { user } = useAuth0();
  const { data: organization, isLoading: organizationLoading } = useQuery(
    ['organization', user.sub],
    useOrganization(),
    {
      staleTime: 1000 * 60, // query remains cached for 1 minute
    },
  );
  return {
    selfServeEnabled: organization?.data.self_serve_enabled,
    isLoading: organizationLoading,
  };
}
