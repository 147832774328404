import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Loading';

// allows access to users who are authenticated
function ProtectedRoute() {
  return <Outlet />;
}

export default withAuthenticationRequired(ProtectedRoute, {
  onRedirecting: () => <Loading />,
});
