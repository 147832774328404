import { useQueryClient } from '@tanstack/react-query';
import { Form, Modal, Space, Steps, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useRawExport } from '../../../../api/Questions';
import { ExportOption } from '../../../../constants';
import { LightGray } from '../../../../styles';
import { questionType } from '../../../../types';
import FormatSelection from './FormatSelection';
import OptionSelection from './OptionSelection';
import QuestionSelection from './QuestionSelection';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    overflow-x: scroll;
    max-height: calc(100vh - 250px);
  }
`;

const StyledSteps = styled(Steps)`
  padding: 14px 12px;
  background-color: ${LightGray};

  .ant-steps-item-title {
    font-size: 12px;
  }

  .ant-steps-small .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: -1px;
  }
`;

const ExportSteps = {
  QUESTION_SELECTION: 0,
  OPTION_SELECTION: 1,
  FORMAT_SELECTION: 2,
};

function RawDataExportModal({ visible, closeModal, openEndedQuestions, surveyId }) {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(ExportSteps.QUESTION_SELECTION);

  const queryClient = useQueryClient();
  const getRawExport = useRawExport();

  const [isLoading, setLoading] = useState(false);

  const onFinish = async (formValues) => {
    const { questions, option, format } = formValues;
    setLoading(true);
    questions.forEach(async (id) => {
      let filters = { export_format: format };
      if (option === ExportOption.THEMES) {
        filters = { ...filters, only_themes: true };
      } else if (option === ExportOption.CUSTOM) {
        filters = { ...filters, deselected: formValues[id] || [] };
      }
      const { body, headers } = await queryClient.fetchQuery({
        queryKey: ['rawData', { questionId: id, filters, surveyId }],
        queryFn: getRawExport,
        onError: () => {
          message.error('Something went wrong exporting your data');
        },
      });
      const filename = headers.get('Content-Disposition').split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([body]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
    closeModal();
    setCurrent(ExportSteps.QUESTION_SELECTION);
    setLoading(false);
  };

  const steps = {
    [ExportSteps.QUESTION_SELECTION]: {
      onOk: () => setCurrent(ExportSteps.OPTION_SELECTION),
      okText: 'Next',
      onCancel: closeModal,
      cancelText: 'Cancel',
    },
    [ExportSteps.OPTION_SELECTION]: {
      onOk: () => setCurrent(ExportSteps.FORMAT_SELECTION),
      okText: 'Next',
      onCancel: () => setCurrent(ExportSteps.QUESTION_SELECTION),
      cancelText: 'Back',
    },
    [ExportSteps.FORMAT_SELECTION]: {
      onOk: onFinish,
      okText: 'Export',
      onCancel: () => setCurrent(ExportSteps.OPTION_SELECTION),
      cancelText: 'Back',
    },
  };

  return (
    <StyledModal
      title="Export raw data"
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(steps[current].onOk)
          .catch(() => {});
      }}
      okText={steps[current].okText}
      okButtonProps={{ disabled: !openEndedQuestions.length }}
      onCancel={steps[current].onCancel}
      cancelText={steps[current].cancelText}
      confirmLoading={isLoading}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <Space direction="vertical" size="middle">
        <StyledSteps
          size="small"
          current={current}
          items={[{ title: 'Questions' }, { title: 'Export Option' }, { title: 'Format' }]}
        />
        <Form form={form} layout="vertical" preserve={false} colon={false}>
          <QuestionSelection
            openEndedQuestions={openEndedQuestions}
            hidden={current !== ExportSteps.QUESTION_SELECTION}
          />
          <OptionSelection
            openEndedQuestions={openEndedQuestions}
            surveyId={surveyId}
            hidden={current !== ExportSteps.OPTION_SELECTION}
          />
          <FormatSelection hidden={current !== ExportSteps.FORMAT_SELECTION} />
        </Form>
      </Space>
    </StyledModal>
  );
}

RawDataExportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openEndedQuestions: PropTypes.arrayOf(questionType).isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default RawDataExportModal;
