import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Modal, Row, Space, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTagResponses } from '../../../../api/Responses';
import { PhaseType } from '../../../../constants';
import useParams from '../../../../hooks/useParams';
import { themeType } from '../../../../types';
import useUtils from '../../utils';
import TagResponsesButton from './TagResponsesButton';

const { Text } = Typography;
const { confirm } = Modal;

const Size12Text = styled(Text)`
  font-size: 12px;
`;

const LinkSize12Text = styled(Size12Text)`
  color: #1890ff;
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  border-color: ${(props) => (props.$isSelected ? '#69C0FF' : '#d9d9d9')};
  ${(props) => props.$isSelected && 'background: #fafafa'};
  ${(props) => props.$isSelected && 'box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15)'};
  ${(props) => !props.$isSelected && 'cursor: pointer'};
  background: #e6f7ff;

  :hover {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }

  .ant-card-body {
    padding: 8px 12px;
  }
`;

function ThemeCard({
  isSelected,
  theme,
  selectedResponseIds,
  setSelectedRows,
  showResponsesForTheme,
  setSelectedThemeId,
  openThemeModal,
  responseQueryFilters,
  responseCount,
  handleUntagResponses,
}) {
  const { codeFrameId } = useParams();
  const {
    getUntaggedResponses,
    updateResponsesForTagResponses,
    updateThemesForTagResponses,
    getTaggedResponses,
  } = useUtils();

  const tagResponses = useMutation(useTagResponses());

  const handleTagResponses = async () => {
    // only include responses not already tagged with themeId to prevent double-tagging
    const responseIds = await getUntaggedResponses({
      themeId: theme.id,
      responseIds: selectedResponseIds,
      filters: responseQueryFilters,
    });
    // don't bother tagging if there are no responses to tag
    // e.g., if all selected responses are already tagged with that theme
    if (!responseIds.length) {
      return;
    }

    try {
      await tagResponses.mutateAsync({
        data: {
          response_ids: responseIds,
          theme_id: theme.id,
          phase: PhaseType.DISCOVERY,
          code_frame_id: codeFrameId,
          is_theme: true,
        },
      });
      updateThemesForTagResponses({ themeId: theme.id, responseIds, isTheme: true });
      updateResponsesForTagResponses({ themeId: theme.id, responseIds });
      setSelectedRows({});
    } catch {
      message.error('Something went wrong tagging responses');
    }
  };

  const untagResponses = async () => {
    const responseIds = await getTaggedResponses({
      themeId: theme.id,
      responseIds: selectedResponseIds,
      filters: responseQueryFilters,
    });
    if (!responseIds.length) {
      return;
    }
    confirm({
      title: `Are you sure you want to untag ${selectedResponseIds.length} responses?`,
      content: 'This cannot be undone.',
      okText: 'Untag',
      okType: 'danger',
      onOk: async () => {
        handleUntagResponses(theme.id, responseIds);
      },
    });
  };

  return (
    <StyledCard $isSelected={isSelected} onClick={() => setSelectedThemeId(`t${theme.id}`)}>
      <Space direction="vertical" size={4}>
        <Row justify="space-between" wrap={false}>
          {theme.name}
          <Button
            onClick={(e) => {
              e.stopPropagation();
              openThemeModal();
            }}
            type="link"
            size="small"
            icon={<EditOutlined />}
          />
        </Row>
        <Row justify="space-between" wrap={false}>
          <Size12Text>
            {`${theme.response_ids.length} | ${Math.round(
              (theme.response_ids.length / responseCount) * 100,
            )}%`}
          </Size12Text>
          <LinkSize12Text
            onClick={(e) => {
              e.stopPropagation();
              showResponsesForTheme(theme.id);
            }}
          >
            See responses
          </LinkSize12Text>
        </Row>
        {isSelected && (
          <Space direction="vertical" size={4}>
            <TagResponsesButton
              onClick={handleTagResponses}
              loading={tagResponses.isLoading}
              numResponses={selectedResponseIds.length}
            />
            <TagResponsesButton
              onClick={untagResponses}
              loading={tagResponses.isLoading}
              numResponses={selectedResponseIds.length}
              isTag={false}
            />
          </Space>
        )}
      </Space>
    </StyledCard>
  );
}

ThemeCard.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  theme: themeType.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  showResponsesForTheme: PropTypes.func.isRequired,
  setSelectedThemeId: PropTypes.func.isRequired,
  openThemeModal: PropTypes.func.isRequired,
  responseQueryFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  responseCount: PropTypes.number.isRequired,
  handleUntagResponses: PropTypes.func.isRequired,
};

export default ThemeCard;
