import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUpdateQuestion } from '../../api/Questions';
import { useQuestions, useUpdateSurvey } from '../../api/Surveys';
import { SurveyTools, SurveyTypes } from '../../constants';

const { Option } = Select;
const { TextArea } = Input;
const { Paragraph } = Typography;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;
const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin-bottom: 4px;
  }
`;

const StyledSpin = styled(Spin)`
  width: 100%;
`;

function EditSurveyDrawer({ survey, setSelectedSurvey }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [editedQuestions, setEditedQuestions] = useState({});
  const [detailsUpdating, setDetailsUpdating] = useState(false);

  const { data: questions, isLoading: questionsLoading } = useQuery(
    ['questions', { surveyId: survey?.id }],
    useQuestions(),
    {
      enabled: !!survey,
    },
  );
  const updateSurvey = useMutation(useUpdateSurvey());
  const updateQuestion = useMutation(useUpdateQuestion());

  const onClose = () => {
    setEditedQuestions({});
    setSelectedSurvey(null);
  };

  const handleUpdateQuestion = async (questionId, value) => {
    updateQuestion.mutateAsync({
      questionId,
      data: JSON.stringify({
        name: value,
      }),
    });
    queryClient.setQueryData(['questions', { surveyId: survey.id }], (prevQuestions) => {
      const updatedQuestions = prevQuestions.data.map((q) =>
        q.id === questionId ? { ...q, name: value } : q,
      );
      return { ...prevQuestions, data: updatedQuestions };
    });
  };

  const handleUpdateSurvey = async (formValues) => {
    await updateSurvey.mutateAsync({
      surveyId: survey.id,
      data: JSON.stringify(formValues),
    });
    queryClient.setQueryData(['surveys'], (prevSurveys) => {
      const updatedSurveys = prevSurveys.data.map((s) =>
        s.id === survey.id
          ? {
              ...s,
              name: formValues.survey_title,
              survey_type: formValues.survey_type,
              survey_goals: formValues.survey_goals,
              survey_tool: formValues.survey_tool,
            }
          : s,
      );
      return { ...prevSurveys, data: updatedSurveys };
    });
  };

  const handleSave = async (formValues) => {
    setDetailsUpdating(true);
    const promises = [
      handleUpdateSurvey(formValues),
      Object.keys(editedQuestions)?.map((questionId) =>
        handleUpdateQuestion(questionId, editedQuestions[questionId]),
      ),
    ];
    try {
      await Promise.all(promises);
      message.success('Survey details updated successfully');
      onClose();
    } catch (error) {
      message.error('Something went wrong');
    } finally {
      setDetailsUpdating(false);
    }
  };

  useEffect(() => {
    if (survey) {
      form.setFieldsValue({
        survey_title: survey.name,
        survey_type: survey.survey_type,
        survey_goals: survey.survey_goals,
        survey_tool: survey.survey_tool,
      });
    }
  }, [form, survey]);

  return (
    <Drawer
      open={!!survey}
      onClose={() => setSelectedSurvey(null)}
      closable={false}
      width={570}
      title="Edit Survey Details"
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  handleSave(values);
                })
                .catch(() => {});
            }}
            type="primary"
            loading={detailsUpdating}
            disabled={detailsUpdating}
          >
            Save Changes
          </Button>
        </Space>
      }
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Title"
              name="survey_title"
              rules={[{ required: true, message: 'Name is required.' }]}
            >
              <Input placeholder="How your survey will be labeled" />
            </Form.Item>
            <Form.Item label="Type" name="survey_type">
              <Select allowClear placeholder="Select">
                {SurveyTypes.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Tool/Method" name="survey_tool">
              <Select allowClear placeholder="Select">
                {SurveyTools.map((tool) => (
                  <Option key={tool} value={tool}>
                    {tool}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Goals" name="survey_goals">
              <TextArea placeholder="Add research questions, goals and desired outcomes" />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Typography.Text>Edit Questions</Typography.Text>
        </Col>
        {questionsLoading && (
          <Col span={24}>
            <StyledSpin />
          </Col>
        )}
        <Col span={24}>
          {questions?.data.map((question) => (
            <div key={question.id} data-cy="question-list">
              <Typography.Text
                data-cy="edit-question"
                editable={{
                  onChange: (value) => {
                    setEditedQuestions({ ...editedQuestions, [question.id]: value });
                  },
                }}
              >
                {editedQuestions[question.id] || question.text}
              </Typography.Text>
              <StyledDivider />
            </div>
          ))}
        </Col>
        <Col span={24}>
          <StyledParagraph strong>Need something else?</StyledParagraph>
          <StyledParagraph>
            To make advanced changes or to delete a survey,{' '}
            <a href="mailto:support@fathomthat.ai">contact us</a>
          </StyledParagraph>
        </Col>
      </Row>
    </Drawer>
  );
}

EditSurveyDrawer.defaultProps = {
  survey: null,
};

EditSurveyDrawer.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    survey_tool: PropTypes.string,
    survey_type: PropTypes.string,
    survey_goals: PropTypes.string,
  }),
  setSelectedSurvey: PropTypes.func.isRequired,
};

export default EditSurveyDrawer;
