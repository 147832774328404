import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export default function usePermissionedSurveyIds() {
  const [surveyIds, setSurveyIds] = useState([]);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const permissionedSurveyIds = user['https://avalancheinsights.com/surveyIds'];
      setSurveyIds(permissionedSurveyIds);
    }
  }, [user, isAuthenticated]);

  return surveyIds;
}
