import { HolderOutlined } from '@ant-design/icons';
import { Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { themeType } from '../../../types';

const { Text, Paragraph } = Typography;

const Size12Text = styled(Text)`
  font-size: 12px;
`;

const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin-bottom: 0px;
  }
`;

const StyledCard = styled(Card)`
  border-color: #d9d9d9;
  width: 232px;
  height: 85px;
  cursor: grab;
  :hover {
    background-color: #fafafa;
  }
`;

const ThemeShadow = styled.div`
  background-color: #f0f0f0;
  width: 232px;
  height: 85px;
`;

function ThemeCard({ theme, responseCount, isDragging }) {
  return isDragging ? (
    <ThemeShadow />
  ) : (
    <StyledCard data-cy="draggable-theme" size="small">
      <Row justify="space-between" wrap={false}>
        <Col>
          <StyledParagraph ellipsis={{ rows: 2, tooltip: true }}>{theme.name}</StyledParagraph>
          <Size12Text>
            {`${theme.response_ids.length} | ${Math.round(
              (theme.response_ids.length / responseCount) * 100,
            )}%`}
          </Size12Text>
        </Col>
        <Col>
          <Text type="secondary">
            <HolderOutlined />
          </Text>
        </Col>
      </Row>
    </StyledCard>
  );
}

ThemeCard.defaultProps = {
  isDragging: false,
};

ThemeCard.propTypes = {
  theme: themeType.isRequired,
  responseCount: PropTypes.number.isRequired,
  isDragging: PropTypes.bool,
};

export default ThemeCard;
