import { ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Button, PageHeader, Tabs, Tag } from 'antd';
import { Splitter } from 'antd-v5';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSegmentSummary } from '../../api/CodeFrames';
import { useResponseOptions } from '../../api/Questions';
import { useQuestions, useSurvey } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import { SegmentType, SurveyDashboardTabs, SurveyStatus, UserRoleType } from '../../constants';
import useParams from '../../hooks/useParams';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';
import InviteGuestModal from './InviteGuestModal';
import ResponsesTab from './ResponsesTab';
import SegmentsSummaryPanel from './SegmentsSummaryPanel';
import SentimentsTab from './SentimentsTab';
import SummaryTab from './SummaryTab';
import ThemesTab from './ThemesTab';

const { Panel } = Splitter;

const StyledSplitter = styled(Splitter)`
  position: absolute;
  left: 0;
  right: 0;

  && .ant-splitter-bar-collapse-bar {
    opacity: unset;
    font-size: 14px;
    width: 14px;
    height: 40px;
  }

  && .ant-splitter-bar-dragger:after {
    height: 40px;
  }
`;

const StyledDiv = styled.div`
  padding: 0px 24px 24px;
  height: 100%;
  overflow: auto;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
`;

function SurveyDashboard() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const roles = user['https://avalancheinsights.com/roles'];
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();
  const { surveyId } = useParams();
  const [searchParams] = useSearchParams();
  const getResponseOptions = useResponseOptions();

  const [activeTab, setActiveTab] = useState(SurveyDashboardTabs.SUMMARY);
  const [selectedQuestionId, setSelectedQuestionId] = useState(undefined);
  const [isInviteModalVisible, setInviteModalVisible] = useState(false);
  const [selectedSegmentQuestionId, setSelectedSegmentQuestionId] = useState(undefined);
  const [statAssistEnabled, setStatAssistEnabled] = useState(true);
  const [activeSegmentTypes, setActiveSegmentTypes] = useState([]);

  const { data: survey, isLoading: surveyLoading } = useQuery(
    ['survey', { surveyId }],
    useSurvey(),
  );

  const { data: questions, isLoading: questionsLoading } = useQuery(
    ['surveyDashboardQuestions', { surveyId }],
    useQuestions(),
    {
      onSuccess: (data) => {
        const questionId = parseInt(searchParams.get('questionId'), 10);
        if (data.data.some((q) => q.has_themes && q.code_frame_id && q.id === questionId)) {
          setSelectedQuestionId(questionId);
          setActiveTab(SurveyDashboardTabs.THEMES);
        } else {
          setSelectedQuestionId(data.data.find((q) => q.has_themes)?.id);
        }
      },
    },
  );

  const questionResponseOptions = useQueries({
    queries: (questions?.data || [])
      .filter((q) => q.is_filterable)
      .map((q) => {
        return {
          queryKey: ['responseOptions', { questionId: q.id, surveyId }],
          queryFn: getResponseOptions,
          select: (data) => ({ ...data, questionId: q.id }),
        };
      }),
  });

  const { data: segmentSummary, isLoading: segmentSummaryLoading } = useQuery(
    [
      'segmentSummary',
      {
        codeFrameId: questions?.data.find((q) => q.id === selectedQuestionId)?.code_frame_id,
        segmentId: selectedSegmentQuestionId,
        surveyId,
      },
    ],
    useSegmentSummary(),
    {
      enabled: !!(selectedQuestionId && selectedSegmentQuestionId),
    },
  );

  const backNavigation = () => {
    if (selfServeEnabled && roles.includes(UserRoleType.ADMINISTRATOR || UserRoleType.EDITOR)) {
      return (
        <BackButton
          onClick={() => navigate(`/surveys/${surveyId}/portal`)}
          text="Back to Theme Engine Portal"
        />
      );
    }
    return <BackButton onClick={() => navigate('/')} text="Back to Survey List" />;
  };

  const showSegmentSummary =
    activeTab === SurveyDashboardTabs.THEMES &&
    selectedSegmentQuestionId &&
    statAssistEnabled &&
    activeSegmentTypes.every((st) => st === SegmentType.ATTRIBUTE) &&
    questions?.data
      .find((q) => q.id === selectedQuestionId)
      ?.significant_segments.includes(selectedSegmentQuestionId);

  if (organizationLoading || surveyLoading || questionsLoading) {
    return <Loading />;
  }

  const tabData = [
    {
      key: SurveyDashboardTabs.SUMMARY,
      label: 'Summary',
      children: (
        <SummaryTab
          questions={questions}
          setActiveTab={setActiveTab}
          setSelectedOpenEndedQuestionId={setSelectedQuestionId}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.THEMES,
      label: 'Themes',
      children: (
        <ThemesTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          questionResponseOptions={questionResponseOptions}
          selectedSegmentQuestionId={selectedSegmentQuestionId}
          setSelectedSegmentQuestionId={setSelectedSegmentQuestionId}
          statAssistEnabled={statAssistEnabled}
          setStatAssistEnabled={setStatAssistEnabled}
          highlightThemeIds={segmentSummary?.data?.theme_ids}
          setActiveSegmentTypes={setActiveSegmentTypes}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.SENTIMENTS,
      label: 'Sentiments',
      children: (
        <SentimentsTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.RESPONSES,
      label: 'Responses',
      children: (
        <ResponsesTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          questionResponseOptions={questionResponseOptions}
        />
      ),
    },
  ];

  return (
    <StyledSplitter>
      <Panel defaultSize="70%" min="60%">
        <StyledDiv>
          <PageHeader
            ghost={false}
            title={survey?.data.name}
            breadcrumbRender={backNavigation}
            tags={
              survey?.data.survey_status !== SurveyStatus.PUBLISHED && (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  This survey is not yet published. This dashboard may not be the final version.
                </Tag>
              )
            }
          />
          <StyledTabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={tabData}
            tabBarExtraContent={
              roles.includes(UserRoleType.ADMINISTRATOR) && (
                <Button
                  type="link"
                  icon=<UserAddOutlined />
                  onClick={() => setInviteModalVisible(true)}
                >
                  Manage Guests
                </Button>
              )
            }
          />
          {roles.includes(UserRoleType.ADMINISTRATOR) && (
            <InviteGuestModal
              visible={isInviteModalVisible}
              closeModal={() => setInviteModalVisible(false)}
              surveyId={surveyId}
            />
          )}
        </StyledDiv>
      </Panel>
      {showSegmentSummary && (
        <Panel min="20%" collapsible>
          <SegmentsSummaryPanel
            question={questions?.data.find((q) => q.id === selectedQuestionId)}
            segmentQuestion={questions?.data.find((q) => q.id === selectedSegmentQuestionId)}
            summary={segmentSummary?.data}
            loading={segmentSummaryLoading}
            responseOptions={
              questionResponseOptions.find(
                (opt) => opt.data?.questionId === selectedSegmentQuestionId,
              )?.data.data
            }
          />
        </Panel>
      )}
    </StyledSplitter>
  );
}

export default SurveyDashboard;
