import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DetailItem = styled.div`
  margin-bottom: 20px;
`;

const DetailItemTitle = styled.div`
  font-size: 16px;
`;

const DetailItemDesc = styled.div`
  font-size: 14px;
  color: #00000073;
`;

function SurveyDetailsModal({ survey, closeModal }) {
  return (
    <Modal title="Details" open={!!survey} footer={null} onCancel={closeModal}>
      <DetailItem>
        <DetailItemTitle>Survey Tool</DetailItemTitle>
        <DetailItemDesc>{survey?.survey_tool}</DetailItemDesc>
      </DetailItem>
      <DetailItem>
        <DetailItemTitle>Survey Type</DetailItemTitle>
        <DetailItemDesc>{survey?.survey_type}</DetailItemDesc>
      </DetailItem>
      <DetailItem>
        <DetailItemTitle>Survey Title</DetailItemTitle>
        <DetailItemDesc>{survey?.name}</DetailItemDesc>
      </DetailItem>
      <DetailItem>
        <DetailItemTitle>Survey Goals</DetailItemTitle>
        <DetailItemDesc>{survey?.survey_goals}</DetailItemDesc>
      </DetailItem>
    </Modal>
  );
}

SurveyDetailsModal.defaultProps = {
  survey: null,
};

SurveyDetailsModal.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    survey_tool: PropTypes.string,
    survey_type: PropTypes.string,
    survey_goals: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
};

export default SurveyDetailsModal;
