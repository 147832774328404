import useFetch from './useFetch';

export function useUsers() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { orgId }] = queryKey;
    return fetcher({ path: `v1/onboarding/organizations/${orgId}/users/` });
  };
}

export function useOrganization() {
  const fetcher = useFetch();
  return () => fetcher({ path: 'v1/onboarding/organizations/me/' });
}

export function useUpdateRole() {
  const fetcher = useFetch();
  return ({ userId, data }) =>
    fetcher({
      path: `v1/onboarding/users/${userId}/role/update/`,
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useDeleteUser() {
  const fetcher = useFetch();
  return ({ userId }) =>
    fetcher({
      path: `v1/onboarding/users/${userId}/delete/`,
      method: 'DELETE',
    });
}

export function useUpdateOrganization() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: 'v1/onboarding/organizations/update/',
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useVerifyEmail() {
  const fetcher = useFetch();
  return () => fetcher({ path: 'v1/onboarding/users/verify/', method: 'POST' });
}

// the following apis do not require authentication
export function useOrganizationByName() {
  return async ({ queryKey }) => {
    const [, { name }] = queryKey;
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/onboarding/organizations/${name}/`,
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}

export function useValidateAuth0JWT() {
  return async ({ data }) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/onboarding/validate-auth0-jwt/`,
      { method: 'POST', body: JSON.stringify(data) },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}

export function useSignup() {
  return async ({ data }) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/onboarding/users/signup/`,
      { method: 'PUT', body: JSON.stringify(data) },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}
