import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useValidateAuth0JWT } from '../api/Onboarding';
import Loading from '../components/Loading';
import NotFound from '../pages/NotFound';

// only allow access if url contains a session token
// this indicates the url has been redirected from auth0
function SignupRoute() {
  const { isLoading, isAuthenticated } = useAuth0();
  const [searchParams] = useSearchParams();
  const [verifying, setVerifying] = useState(true);
  const [jwtPayload, setJwtPayload] = useState(null);
  const { mutateAsync: validateAuth0JWT } = useMutation(useValidateAuth0JWT());

  useEffect(() => {
    async function decodeJwt() {
      try {
        const response = await validateAuth0JWT({
          data: { auth0_session_token: searchParams.get('session_token') },
        });
        setJwtPayload(response.data);
      } finally {
        setVerifying(false);
      }
    }
    decodeJwt();
  }, [searchParams, validateAuth0JWT]);

  if (isLoading || verifying) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <NotFound />;
  }

  return jwtPayload ? <Outlet context={jwtPayload} /> : <Navigate to="/" />;
}

export default SignupRoute;
