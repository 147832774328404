import { SURVEY_ID_HEADER } from '../constants';
import useFetch from './useFetch';

export function useCodeFrameThemes() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId }] = queryKey;
    return fetcher({ path: `v1/codeframes/${codeFrameId}/themes/` });
  };
}

export function useCodeFrameResponses() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId, filters }] = queryKey;
    return fetcher({
      path: `v1/codeframes/${codeFrameId}/responses/`,
      filters,
    });
  };
}

export function useUpdateCftName() {
  const fetcher = useFetch();
  return ({ codeFrameId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/update-cft-name/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useFinalizeCodeFrame() {
  const fetcher = useFetch();
  return ({ codeFrameId }) =>
    fetcher({ path: `v1/codeframes/${codeFrameId}/finalize/`, method: 'PUT' });
}

export function useGroupThemes() {
  const fetcher = useFetch();
  return ({ codeFrameId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/group-themes/`,
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useOmitTheme() {
  const fetcher = useFetch();
  return ({ codeFrameId, themeId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/themes/${themeId}/omit/`,
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useThemeSummary() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId, themeId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/codeframes/${codeFrameId}/themes/${themeId}/summary/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useUpdateThemeSummary() {
  const fetcher = useFetch();
  return ({ codeFrameId, themeId, surveyId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/themes/${themeId}/update-summary/`,
      method: 'PATCH',
      data: JSON.stringify(data),
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
}

export function useSuggestedThemes() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId }] = queryKey;
    return fetcher({ path: `v1/codeframes/${codeFrameId}/suggested-themes/` });
  };
}

export function useQuestionSummary() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/codeframes/${codeFrameId}/summary/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useUpdateQuestionSummary() {
  const fetcher = useFetch();
  return ({ codeFrameId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/update-summary/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useSegmentSummary() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId, segmentId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/codeframes/${codeFrameId}/segments/${segmentId}/summary/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useSegmentSummaryTheme() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { codeFrameId, segmentId, themeId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/codeframes/${codeFrameId}/segments/${segmentId}/themes/${themeId}/summary/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}
