import { Card, Progress, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  SentimentGrayDark,
  SentimentGrayLight,
  SentimentGreenDark,
  SentimentGreenLight,
  SentimentRedDark,
  SentimentRedLight,
} from '../../../styles';
import { dashboardThemeType } from '../../../types';

const { Text } = Typography;

const StyledCard = styled(Card)`
  border: 0;
`;

const StyledProgressBar = styled(Progress)`
  height: 16px;
  .ant-progress-success-bg,
  .ant-progress-bg {
    height: 16px !important;
  }
  margin-bottom: 34px;
`;

const StyledConcernText = styled.p`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const ValueHeader = styled.div`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 18px;
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  padding: 0px 5px;

  svg {
    margin: 4px 0px;
  }
`;

const PositiveTag = styled(StyledTag)`
  border-color: ${SentimentGreenDark};
  color: ${SentimentGreenDark};
  background-color: ${SentimentGreenLight}80;
`;

const NegativeTag = styled(StyledTag)`
  border-color: ${SentimentRedDark};
  color: ${SentimentRedDark};
  background-color: ${SentimentRedLight}80;
`;

const NeutralTag = styled(StyledTag)`
  border-color: ${SentimentGrayDark};
  color: ${SentimentGrayDark};
  background-color: ${SentimentGrayLight}80;
`;

const StyledText = styled(Text)`
  margin-left: 5px;
  color: inherit;
`;

const BAR_COLOR = ['#00EBFF', '#967DFF', '#FF9600', '#FFD700', '#FF0046', '#0000FF'];

const circleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="currentColor"
    viewBox="0 0 12 12"
  >
    <circle cx="6" cy="6" r="6" />
  </svg>
);

function ConcernCard({ theme, index, displaySentiment }) {
  const barColor = BAR_COLOR[index % BAR_COLOR.length];
  const hasSentimentValues = typeof theme.positive_count !== typeof undefined;

  return (
    <StyledCard data-cy="concern-card">
      <ValueHeader>{theme.percentage}%</ValueHeader>
      <StyledProgressBar strokeColor={barColor} percent={theme.percentage} showInfo={false} />
      <StyledConcernText>
        {`${theme.percentage}% (${theme.response_count}) of responses to this question contain the theme `}
        <b>{theme.customer_theme_name || theme.name}</b>
      </StyledConcernText>
      {displaySentiment && (
        <>
          <PositiveTag icon={circleIcon}>
            {hasSentimentValues && (
              <StyledText>
                {Math.round((theme.positive_count * 100) / theme.response_count)}%
              </StyledText>
            )}
          </PositiveTag>
          <NegativeTag icon={circleIcon}>
            {hasSentimentValues && (
              <StyledText>
                {Math.round((theme.negative_count * 100) / theme.response_count)}%
              </StyledText>
            )}
          </NegativeTag>
          <NeutralTag icon={circleIcon}>
            {hasSentimentValues && (
              <StyledText>
                {Math.round((theme.neutral_count * 100) / theme.response_count)}%
              </StyledText>
            )}
          </NeutralTag>
        </>
      )}
    </StyledCard>
  );
}

ConcernCard.propTypes = {
  index: PropTypes.number.isRequired,
  theme: dashboardThemeType.isRequired,
  displaySentiment: PropTypes.bool.isRequired,
};

export default ConcernCard;
