import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { Alert, Button, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useVerifyEmail } from '../../api/Onboarding';

const StyledDiv = styled.div`
  background-color: #fffbe6;
  width: 100vw;
  align-self: center;
`;

const StyledAlert = styled(Alert)`
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
`;

function EmailVerification() {
  const { user } = useAuth0();
  const isEmailVerified = user.email_verified;
  const { mutate: verifyEmail, isLoading: isVerifyEmailLoading } = useMutation(useVerifyEmail());

  const verificationEmailNotification = async () => {
    verifyEmail(
      {},
      {
        onSuccess: (data) => {
          if (data.response.result === 'success') {
            notification.success({
              message: 'Verification email sent',
              description:
                'Please check your email inbox and follow the instructions to verify your email address',
              placement: 'top',
            });
          }
        },
      },
    );
  };

  return (
    <StyledDiv>
      {!isEmailVerified && (
        <StyledAlert
          message="Please verify your email address"
          type="warning"
          banner
          action={
            <Button
              type="link"
              size="small"
              onClick={() => verificationEmailNotification()}
              loading={isVerifyEmailLoading}
            >
              Verify Now
            </Button>
          }
        />
      )}
    </StyledDiv>
  );
}
export default EmailVerification;
