import { useQuery } from '@tanstack/react-query';
import { Collapse, Form, Radio, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useCodeFrames } from '../../../../api/Questions';
import { ExportOption } from '../../../../constants';
import { questionType } from '../../../../types';
import ThemePicker from '../../ThemePicker';

const { Panel } = Collapse;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

function OptionSelection({ openEndedQuestions, surveyId, hidden }) {
  const form = Form.useFormInstance();
  const questions = Form.useWatch('questions', form);
  const option = Form.useWatch('option', form);

  const { data: questionThemes } = useQuery(
    ['codeFrames', { questionIds: questions?.join(), filters: { only_final: true }, surveyId }],
    useCodeFrames(),
    { enabled: !hidden },
  );

  return (
    <Form.Item
      label="Select the export option"
      name="option"
      initialValue={ExportOption.ALL}
      hidden={hidden}
    >
      <StyledRadioGroup>
        <Space direction="vertical">
          <Tooltip title="Export all themes and subthemes" placement="left">
            <Radio value={ExportOption.ALL}>All</Radio>
          </Tooltip>
          <Tooltip title="Export only themes but no subthemes" placement="left">
            <Radio value={ExportOption.THEMES}>Only Themes</Radio>
          </Tooltip>
          <Tooltip title="Export any combination of themes and subthemes" placement="left">
            <Radio value={ExportOption.CUSTOM}>Custom Selection</Radio>
          </Tooltip>
          {option === ExportOption.CUSTOM && (
            <StyledCollapse>
              {questionThemes?.data.map((qt) => (
                <Panel
                  key={qt.question_id}
                  header={openEndedQuestions.find((q) => q.id === qt.question_id).text}
                >
                  <StyledFormItem name={qt.question_id} valuePropName="deselectedThemes">
                    <ThemePicker
                      themes={qt.theme_json.themes}
                      switchTooltip="Include theme in export"
                    />
                  </StyledFormItem>
                </Panel>
              ))}
            </StyledCollapse>
          )}
        </Space>
      </StyledRadioGroup>
    </Form.Item>
  );
}

OptionSelection.propTypes = {
  openEndedQuestions: PropTypes.arrayOf(questionType).isRequired,
  surveyId: PropTypes.number.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default OptionSelection;
