import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useUpdateOrganization } from '../../api/Onboarding';

const StyledForm = styled(Form)`
  padding: 12px 16px;
`;

function EditOrganizationModal({ visible, closeModal, organization }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { mutate: updateOrganization, isLoading: updateOrganizationLoading } = useMutation(
    useUpdateOrganization(),
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        id: organization.data.id,
        name: organization.data.name,
      });
    }
  }, [form, organization, visible]);

  const onFinish = (values) => {
    updateOrganization(
      { data: { id: organization.data.id, name: values.name } },
      {
        onSuccess: () => {
          queryClient.setQueryData(['organization'], {
            ...organization,
            data: { ...organization.data, name: values.name },
          });
          message.success('Organization updated');
        },
        onError: () => {
          message.error('Something went wrong updating organization');
        },
        onSettled: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Modal
      title="My Organization"
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      okText="Update"
      onCancel={closeModal}
      confirmLoading={updateOrganizationLoading}
      maskClosable={false}
    >
      <StyledForm form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="name"
          label="Organization Name"
          rules={[
            { required: true, message: 'Organization name required' },
            { whitespace: true, message: 'Organization name cannot be blank' },
          ]}
        >
          <Input placeholder="Enter your organization name" />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

EditOrganizationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default EditOrganizationModal;
