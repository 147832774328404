import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  border-color: #bae7ff;
  background: #bae7ff;
  color: #1890ff;
  font-weight: bold;
  font-size: 12px;
`;

function TagResponsesButton({ onClick, loading, numResponses, isTag }) {
  return (
    <StyledButton block size="small" onClick={onClick} loading={loading}>
      {`${isTag ? 'Tag' : 'Untag'} ${numResponses} responses`}
    </StyledButton>
  );
}

TagResponsesButton.defaultProps = {
  isTag: true,
};

TagResponsesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  numResponses: PropTypes.number.isRequired,
  isTag: PropTypes.bool,
};

export default TagResponsesButton;
