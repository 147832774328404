import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledSpin = styled(Spin)`
  width: 100%;
  height: calc(100vh - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Loading() {
  return <StyledSpin size="large" />;
}

export default Loading;
