import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { themeType } from '../../../../types';

const { Text } = Typography;

const StyledCard = styled(Card)`
  border-color: #d9d9d9;
  background: rgba(230, 247, 255, 0.8);

  .ant-card-body {
    padding: 8px;
  }
`;

const Size12Text = styled(Text)`
  font-size: 12px;
`;

function ThemeGroupCard({ group, responseCount, openGroupModal, children }) {
  const groupResponseCount = new Set(group.children.flatMap((t) => t.response_ids)).size;

  return (
    <StyledCard>
      <Space direction="vertical" size={4}>
        <Row justify="space-between" wrap={false}>
          <Text strong>{group.name}</Text>
          <Button onClick={openGroupModal} type="link" size="small" icon={<EditOutlined />} />
        </Row>
        <Size12Text>
          {`${children.length} themes, ${groupResponseCount} responses | ${Math.round(
            (groupResponseCount / responseCount) * 100,
          )}% of full sample`}
        </Size12Text>
        {children}
      </Space>
    </StyledCard>
  );
}

ThemeGroupCard.propTypes = {
  group: themeType.isRequired,
  responseCount: PropTypes.number.isRequired,
  openGroupModal: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ThemeGroupCard;
