// antd 4.x uses moment.js, while we use dayjs
// this component is adapated from ant's documentation for using dayjs instead
// see https://4x.ant.design/docs/react/replace-moment
import generatePicker from 'antd/es/date-picker/generatePicker';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

// prevents "clone.weekday is not a function" error
// see https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday);
dayjs.extend(localeData);
const DatePicker = generatePicker(dayjsGenerateConfig);

export default DatePicker;
