import useFetch from './useFetch';

export function useInvitations() {
  const fetcher = useFetch();
  return () => fetcher({ path: 'v1/invitations/' });
}

export function useCreateInvitation() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: 'v1/invitations/create/',
      method: 'POST',
      data: JSON.stringify(data),
    });
}

export function useDeleteInvitation() {
  const fetcher = useFetch();
  return ({ invitationId }) =>
    fetcher({
      path: `v1/invitations/${invitationId}/delete/`,
      method: 'DELETE',
    });
}

export function useValidateInvitation() {
  return async ({ token }) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/invitations/validate/`,
      { method: 'POST', body: JSON.stringify({ token }) },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}
