export const GreenPolar6 = '#52C41A';
export const GreenPolar8 = '#237804';
export const BlueDaybreak6 = '#1890FF';
export const GrayNeutral = '#F0F0F0';
export const BlueTitle = '#002766';
export const LightGray = '#FAFAFA';

export const SentimentGreenLight = '#B7EB8F';
export const SentimentGreenDark = '#52C41A';
export const SentimentGrayLight = '#D9D9D9';
export const SentimentGrayDark = '#BFBFBF';
export const SentimentRedLight = '#FFA39E';
export const SentimentRedDark = '#F5222D';

export const BadgeGray = '#D9D9D9';
export const BadgeBlue = '#108EE9';

export const ThemeChartGrayDark = '#8C8C8C';
export const ThemeChartGrayLight = '#D9D9D9';
export const ThemeChartTealDark = '#13C2C2';
export const ThemeChartTealLight = '#87E8DE';

export const themeColors = [
  'cyan',
  'magenta',
  'green',
  'volcano',
  'blue',
  'magenta',
  'lime',
  'geekblue',
  'gold',
  'purple',
  'red',
];
