import { useDraggable } from '@dnd-kit/core';
import PropTypes from 'prop-types';
import React from 'react';
import { themeType } from '../../../types';
import ThemeCard from './ThemeCard';

function DraggableTheme({ theme, responseCount }) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({ id: theme.id });

  return (
    <span
      ref={setNodeRef}
      // pass useDraggable props through
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...listeners}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      <ThemeCard theme={theme} responseCount={responseCount} isDragging={isDragging} />
    </span>
  );
}

DraggableTheme.propTypes = {
  theme: themeType.isRequired,
  responseCount: PropTypes.number.isRequired,
};

export default DraggableTheme;
