import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  border-radius: 50%;
  border: 1px solid #d9d9d9;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 7px;
`;

function ThemeCount({ count }) {
  return <StyledDiv>{count}</StyledDiv>;
}

ThemeCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ThemeCount;
