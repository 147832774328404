import { InfoCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Card, Collapse, Row, Space, Tag, Tooltip, Typography, message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUpdateQuestionSummary } from '../../../../api/CodeFrames';
import GenerateSummaryLoading from '../../../../components/GenerateSummaryLoading';
import { MatomoEvent, SUMMARY_COLLAPSE_KEY, UserRoleType } from '../../../../constants';
import useMatomo from '../../../../hooks/useMatomo';
import useParams from '../../../../hooks/useParams';
import { questionType, summaryType } from '../../../../types';
import EditQuestionSummaryModal from './EditQuestionSummaryModal';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const StyledText = styled(Text)`
  font-size: 12px;
  white-space: pre-line;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding-bottom: 0 !important;
  }
`;

function QuestionSummary({ question, summary }) {
  const { surveyId } = useParams();
  const { trackEvent } = useMatomo();
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const roles = user['https://avalancheinsights.com/roles'];

  const [summaryCollapseKey, setSummaryCollapseKey] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [generateSummaryLoading, setGenerateSummaryLoading] = useState(false);

  const { mutate: updateQuestionSummary } = useMutation(useUpdateQuestionSummary());

  const handleGenerateSummary = () => {
    setGenerateSummaryLoading(true);
    trackEvent(MatomoEvent.GENERATE_QUESTION_SUMMARY);
    updateQuestionSummary(
      { codeFrameId: question.code_frame_id, data: { summary_generated: true } },
      {
        onSuccess: () => {
          // wait 5 seconds before setting loading to false to show animation
          setTimeout(() => {
            queryClient.setQueryData(
              ['questionSummary', { surveyId, codeFrameId: question.code_frame_id }],
              (prevSummary) => ({
                ...prevSummary,
                data: { ...prevSummary.data, summary_generated: true },
              }),
            );
            setGenerateSummaryLoading(false);
          }, 5000);
        },
        onError: () => {
          message.error('Something went wrong generating summary');
          setGenerateSummaryLoading(false);
        },
      },
    );
  };

  const cardContent = () => {
    if (generateSummaryLoading) {
      return <GenerateSummaryLoading />;
    }
    if (summary.summary_generated) {
      return (
        <>
          <StyledText>{summary.summary}</StyledText>
          <StyledCollapse
            collapsible="icon"
            activeKey={summaryCollapseKey}
            onChange={setSummaryCollapseKey}
            ghost
          >
            <Panel key={SUMMARY_COLLAPSE_KEY} header="Theme Overview">
              <StyledText>{summary.theme_overview}</StyledText>
            </Panel>
          </StyledCollapse>
        </>
      );
    }
    return (
      <StyledText type="secondary">
        Click &quot;Generate Summary&quot; to produce an AI-generated summary for the themes,
        sub-themes and key insights from the coded data in this question. Summaries are fully
        editable.
      </StyledText>
    );
  };

  return (
    <div data-cy="question-summary">
      <Row justify="space-between" align="top">
        <Space align="start">
          <Title level={5}>Question summary</Title>
          <Tooltip title="Summary is generated by an AI model based on themes and responses">
            <Tag icon={<InfoCircleOutlined />}>Beta Feature</Tag>
          </Tooltip>
        </Space>
        <Space>
          {(roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)) &&
            (summary.summary_generated ? (
              <Button size="small" onClick={() => setEditModalVisible(true)}>
                Edit summary
              </Button>
            ) : (
              <Button size="small" onClick={handleGenerateSummary} loading={generateSummaryLoading}>
                Generate summary
              </Button>
            ))}
        </Space>
      </Row>
      <Card size="small">{cardContent()}</Card>
      <EditQuestionSummaryModal
        visible={isEditModalVisible}
        closeModal={() => setEditModalVisible(false)}
        summary={summary}
        codeFrameId={question.code_frame_id}
        surveyId={surveyId}
      />
    </div>
  );
}

QuestionSummary.propTypes = {
  question: questionType.isRequired,
  summary: summaryType.isRequired,
};

export default QuestionSummary;
