import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Loading';
import useSelfServeOrganization from '../hooks/useSelfServeOrganization';
import NotFound from '../pages/NotFound';

// allows access to users whose organization has self_serve_enabled=True
function SelfServeRoute() {
  const { selfServeEnabled, isLoading } = useSelfServeOrganization();
  if (isLoading) {
    return <Loading />;
  }
  return selfServeEnabled ? <Outlet /> : <NotFound />;
}

export default SelfServeRoute;
