import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import React from 'react';

function LogoutButton() {
  const { logout } = useAuth0();
  return (
    <Button
      onClick={() => {
        window.Intercom('shutdown');
        logout({ returnTo: window.location.origin });
      }}
      data-cy="logout-button"
    >
      Log Out
    </Button>
  );
}

export default LogoutButton;
