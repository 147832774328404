import { useMutation, useQuery } from '@tanstack/react-query';
import { Badge, Col, Row, Space, Tabs, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSuggestedThemes } from '../../../api/CodeFrames';
import { useUpdateSuggestedTheme } from '../../../api/LLM';
import { useTagResponses } from '../../../api/Responses';
import { useCreateUpdateTheme } from '../../../api/Themes';
import Loading from '../../../components/Loading';
import {
  PhaseType,
  THEME_ENGINE_RANDOM_SAMPLE,
  ThemeDiscoveryTabs,
  ThemeEngineQueryKey,
} from '../../../constants';
import useParams from '../../../hooks/useParams';
import { GrayNeutral } from '../../../styles';
import { queryType, questionType } from '../../../types';
import useUtils from '../utils';
import ResponseTab from './ResponseTab';
import ThemesList from './ThemesList';

const StyledRow = styled(Row)`
  height: calc(100vh - 275px);
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background: ${GrayNeutral};
    color: rgba(0, 0, 0, 0.45);
  }
`;

function ThemeDiscovery({ themes, question }) {
  const { codeFrameId } = useParams();
  const {
    updateResponsesForTagResponses,
    updateThemesForCreateUpdateTheme,
    updateSuggestedThemesForCreateSuggestedTheme,
    updateSuggestedThemesForDismissSuggestedTheme,
    updateThemesForTagResponses,
    updateResponsesForUntagResponses,
  } = useUtils();

  const [activeTab, setActiveTab] = useState(ThemeDiscoveryTabs.RANDOM);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedTabThemeId, setSelectedTabThemeId] = useState(undefined);
  const [selectedSuggestedThemeId, setSelectedSuggestedThemeId] = useState(undefined);
  const [responseQueryFilters, setResponseQueryFilters] = useState({
    with_theme_ids: true,
    order: 'random',
    page_number: 1,
    page_size: THEME_ENGINE_RANDOM_SAMPLE,
  });

  const { data: suggestedThemes, isLoading: suggestedThemesLoading } = useQuery(
    [ThemeEngineQueryKey.SUGGESTED_THEMES, { codeFrameId }],
    useSuggestedThemes(),
    {
      select: (data) => data.data,
      onSuccess: (data) => setSelectedSuggestedThemeId(data[0]?.id),
    },
  );
  const createUpdateTheme = useMutation(useCreateUpdateTheme());
  const tagResponses = useMutation(useTagResponses());
  const updateSuggestedTheme = useMutation(useUpdateSuggestedTheme());

  // selectedResponseIds is a deduplicated list of response ids that are currently selected
  const selectedResponseIds = useMemo(() => {
    return [...new Set(Object.values(selectedRows).flat())];
  }, [selectedRows]);

  useEffect(() => {
    // if the user changes to a tab other than Selected Theme
    // disable the Selected Theme tab
    // otherwise it can be confusing if the user selects a new theme but the tab shows the old theme
    if (activeTab !== ThemeDiscoveryTabs.THEME) {
      setSelectedTabThemeId(undefined);
    }
  }, [activeTab]);

  const showResponsesForTheme = (themeId) => {
    setSelectedTabThemeId(themeId);
    setActiveTab(ThemeDiscoveryTabs.THEME);
  };

  const onDeleteTheme = (themeId) => {
    if (activeTab === ThemeDiscoveryTabs.THEME && selectedTabThemeId === themeId) {
      setActiveTab(ThemeDiscoveryTabs.RANDOM);
      setSelectedTabThemeId(undefined);
    }
  };

  const showSuggestedTheme = (suggestedThemeId) => {
    setSelectedSuggestedThemeId(suggestedThemeId);
    setActiveTab(ThemeDiscoveryTabs.SUGGESTED_THEMES);
  };

  const dismissSuggestedTheme = async (suggestedThemeId) => {
    await updateSuggestedTheme.mutateAsync({
      suggestedThemeId,
      data: { is_dismissed: true },
    });
    if (suggestedThemes.length === 1) {
      if (activeTab === ThemeDiscoveryTabs.SUGGESTED_THEMES) {
        setActiveTab(ThemeDiscoveryTabs.RANDOM);
      }
      setSelectedSuggestedThemeId(undefined);
    } else if (suggestedThemeId === selectedSuggestedThemeId) {
      const currIndex = suggestedThemes.findIndex((st) => st.id === suggestedThemeId);
      const newIndex = currIndex ? currIndex - 1 : 1;
      setSelectedSuggestedThemeId(suggestedThemes[newIndex].id);
    }
    updateSuggestedThemesForDismissSuggestedTheme({ suggestedThemeId });
  };

  const createThemeFromSuggestedTheme = async (suggestedTheme) => {
    // don't create theme if no responses are selected
    if (!selectedResponseIds.length) {
      return;
    }
    try {
      const response = await createUpdateTheme.mutateAsync({
        data: {
          name: suggestedTheme.name,
          code_frame_id: codeFrameId,
          suggested_theme_id: suggestedTheme.id,
        },
      });
      const themeId = response.data.theme_id;

      await tagResponses.mutateAsync({
        data: {
          response_ids: selectedResponseIds,
          theme_id: themeId,
          phase: PhaseType.DISCOVERY,
          code_frame_id: codeFrameId,
          is_theme: true,
        },
      });
      updateResponsesForTagResponses({ themeId, responseIds: selectedResponseIds });
      updateThemesForCreateUpdateTheme({
        id: themeId,
        name: suggestedTheme.name,
        description: suggestedTheme.description,
        responseIds: selectedResponseIds,
      });
      // remove tagged responses from suggested theme response_ids
      const updatedResponseIds = suggestedTheme.response_ids.filter(
        (rid) => !selectedResponseIds.includes(rid),
      );
      // if no responses remain, dismiss the suggested theme
      if (!updatedResponseIds.length) {
        await dismissSuggestedTheme(suggestedTheme.id);
      } else {
        updateSuggestedThemesForCreateSuggestedTheme({
          suggestedThemeId: suggestedTheme.id,
          responseIds: updatedResponseIds,
        });
      }
      setSelectedRows({});
    } catch {
      message.error('Something went wrong creating theme from suggestion');
    }
  };

  const handleUntagResponses = async (themeId, responseIds) => {
    try {
      await tagResponses.mutateAsync({
        data: {
          response_ids: responseIds,
          theme_id: themeId,
          phase: PhaseType.DISCOVERY,
          code_frame_id: codeFrameId,
          is_theme: false,
        },
      });
      updateThemesForTagResponses({
        themeId,
        responseIds,
        isTheme: false,
      });
      // if we're on the "Selected Theme" tab for the theme removed
      // remove the response from the list
      // if not, remove themeId from the list of themes for the response we just untagged
      updateResponsesForUntagResponses({
        themeId,
        responseIds,
        removeResponse: activeTab === ThemeDiscoveryTabs.THEME && themeId === selectedTabThemeId,
      });
      setSelectedRows({});
    } catch {
      message.error('Something went wrong untagging response');
    }
  };

  if (suggestedThemesLoading) {
    return <Loading />;
  }

  const tabData = [
    {
      key: ThemeDiscoveryTabs.RANDOM,
      label: 'Random Sample',
    },
    {
      key: ThemeDiscoveryTabs.ALL,
      label: 'Full Response Set',
    },
    {
      key: ThemeDiscoveryTabs.SUGGESTED_THEMES,
      label: (
        <Space>
          AI-suggested Themes
          <StyledBadge size="medium" count={suggestedThemes.length} />
        </Space>
      ),
      disabled: !suggestedThemes.length,
    },
    {
      key: ThemeDiscoveryTabs.THEME,
      label: 'Selected Theme',
      disabled: !selectedTabThemeId,
    },
  ];

  return (
    <StyledRow gutter={48}>
      <StyledCol xs={24} sm={24} md={6}>
        <ThemesList
          themes={themes}
          question={question}
          selectedResponseIds={selectedResponseIds}
          setSelectedRows={setSelectedRows}
          showResponsesForTheme={showResponsesForTheme}
          onDeleteTheme={onDeleteTheme}
          responseQueryFilters={responseQueryFilters}
          suggestedThemes={suggestedThemes}
          showSuggestedTheme={showSuggestedTheme}
          createThemeFromSuggestedTheme={createThemeFromSuggestedTheme}
          dismissSuggestedTheme={dismissSuggestedTheme}
          handleUntagResponses={handleUntagResponses}
        />
      </StyledCol>
      <StyledCol xs={24} sm={24} md={18}>
        <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabData} />
        <ResponseTab
          themes={themes}
          question={question}
          suggestedThemes={suggestedThemes}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectedResponseIds={selectedResponseIds}
          activeTab={activeTab}
          selectedTabThemeId={selectedTabThemeId}
          queryFilters={responseQueryFilters}
          setQueryFilters={setResponseQueryFilters}
          selectedSuggestedThemeId={selectedSuggestedThemeId}
          setSelectedSuggestedThemeId={setSelectedSuggestedThemeId}
          createThemeFromSuggestedTheme={createThemeFromSuggestedTheme}
          dismissSuggestedTheme={dismissSuggestedTheme}
          handleUntagResponses={handleUntagResponses}
        />
      </StyledCol>
    </StyledRow>
  );
}

ThemeDiscovery.propTypes = {
  question: questionType.isRequired,
  themes: queryType.isRequired,
};

export default ThemeDiscovery;
