import { useQueryClient } from '@tanstack/react-query';
import { ThemeEngineQueryKey } from '../../constants';
import useParams from '../../hooks/useParams';

export default function useUtils() {
  const { codeFrameId } = useParams();
  const queryClient = useQueryClient();

  const getUntaggedResponses = async ({ themeId, responseIds, filters }) => {
    const responses = await queryClient.getQueryData({
      queryKey: [ThemeEngineQueryKey.RESPONSES, { codeFrameId, filters }],
    });
    const filteredResponseIds = responses.data
      .filter((r) => responseIds.includes(r.id) && !r.theme_ids.includes(themeId))
      .map((r) => r.id);
    return [...new Set(filteredResponseIds)];
  };

  const getTaggedResponses = async ({ themeId, responseIds, filters }) => {
    const responses = await queryClient.getQueryData({
      queryKey: [ThemeEngineQueryKey.RESPONSES, { codeFrameId, filters }],
    });
    const filteredResponseIds = responses.data
      .filter((r) => responseIds.includes(r.id) && r.theme_ids.includes(themeId))
      .map((r) => r.id);
    return [...new Set(filteredResponseIds)];
  };

  const updateThemesForTagResponses = ({ themeId, responseIds, isTheme }) => {
    queryClient.setQueryData([ThemeEngineQueryKey.THEMES, { codeFrameId }], (prevThemes) => {
      const updatedThemes = prevThemes.data.map((t) =>
        t.id === themeId
          ? {
              ...t,
              response_ids: isTheme
                ? [...t.response_ids, ...responseIds]
                : t.response_ids.filter((rid) => !responseIds.includes(rid)),
            }
          : t,
      );
      return { ...prevThemes, data: updatedThemes };
    });
  };

  const updateResponsesForTagResponses = ({ themeId, responseIds }) => {
    [ThemeEngineQueryKey.RESPONSES].forEach((key) => {
      queryClient.setQueriesData({ queryKey: [key, { codeFrameId }] }, (prevResponses) => {
        // add themeId to the themes array of each response just tagged
        // this adds tags next to each response in the table body
        const updatedResponses = prevResponses?.data.map((r) =>
          responseIds.includes(r.id) ? { ...r, theme_ids: [...r.theme_ids, themeId] } : r,
        );
        return { ...prevResponses, data: updatedResponses };
      });
    });
  };

  const updateResponsesForUntagResponses = ({ themeId, responseIds, removeResponse }) => {
    if (removeResponse) {
      queryClient.invalidateQueries({
        queryKey: [ThemeEngineQueryKey.RESPONSES, { codeFrameId }],
      });
    }
    [ThemeEngineQueryKey.RESPONSES].forEach((key) => {
      queryClient.setQueriesData({ queryKey: [key, { codeFrameId }] }, (prevResponses) => {
        const updatedResponses = prevResponses?.data.map((r) =>
          !responseIds || responseIds.includes(r.id)
            ? {
                ...r,
                theme_ids: r.theme_ids.filter((t) => t !== themeId),
              }
            : r,
        );
        return { ...prevResponses, data: updatedResponses };
      });
    });
  };

  const updateThemesForCreateUpdateTheme = ({
    id,
    name,
    description,
    childThemeIds,
    responseIds,
  }) => {
    queryClient.setQueryData([ThemeEngineQueryKey.THEMES, { codeFrameId }], (prevThemes) => {
      let updatedThemes;
      // if theme already exists then update it, otherwise add it
      if (prevThemes.data.some((t) => t.id === id)) {
        updatedThemes = prevThemes.data.map((t) => (t.id === id ? { ...t, name, description } : t));
      } else {
        updatedThemes = [
          ...prevThemes.data,
          {
            id,
            name,
            description,
            response_ids: [],
            is_parent: !!childThemeIds,
            parent_theme_id: null,
            is_omitted: false,
            created_at: new Date().toISOString(),
          },
        ];
      }
      // assign responses to theme if passed
      if (responseIds) {
        updatedThemes = updatedThemes.map((t) => {
          if (t.id === id) {
            return {
              ...t,
              response_ids: [...new Set([...t.response_ids, ...responseIds])],
            };
          }
          return t;
        });
      }
      // if theme is a parent, group its children
      if (childThemeIds) {
        updatedThemes = updatedThemes.map((t) => {
          if (childThemeIds.includes(t.id)) {
            return { ...t, parent_theme_id: id, is_omitted: false };
          }
          if (t.parent_theme_id === id && !childThemeIds.includes(t.id)) {
            return { ...t, parent_theme_id: null };
          }
          return t;
        });
      }
      return { ...prevThemes, data: updatedThemes };
    });
  };

  const updateThemesForDeleteTheme = ({ themeId }) => {
    queryClient.setQueryData([ThemeEngineQueryKey.THEMES, { codeFrameId }], (prevThemes) => {
      const updatedThemes = prevThemes.data.map((t) =>
        t.parent_theme_id === themeId ? { ...t, parent_theme_id: null } : t,
      );
      const remainingThemes = updatedThemes.filter((t) => t.id !== themeId);
      return { ...prevThemes, data: remainingThemes };
    });
  };

  const updateSuggestedThemesForCreateSuggestedTheme = ({ suggestedThemeId, responseIds }) => {
    queryClient.setQueryData(
      [ThemeEngineQueryKey.SUGGESTED_THEMES, { codeFrameId }],
      (prevThemes) => {
        const updatedThemes = prevThemes.data.map((st) =>
          st.id === suggestedThemeId ? { ...st, response_ids: responseIds } : st,
        );
        return { ...prevThemes, data: updatedThemes };
      },
    );
  };

  const updateSuggestedThemesForDismissSuggestedTheme = ({ suggestedThemeId }) => {
    queryClient.setQueryData(
      [ThemeEngineQueryKey.SUGGESTED_THEMES, { codeFrameId }],
      (prevThemes) => {
        const updatedThemes = prevThemes.data.filter((st) => st.id !== suggestedThemeId);
        return { ...prevThemes, data: updatedThemes };
      },
    );
  };

  const updateResponsesForEditSentiment = ({ sentiment, responseIdsToUpdate }) => {
    [ThemeEngineQueryKey.RESPONSES].forEach((key) => {
      queryClient.setQueriesData({ queryKey: [key, { codeFrameId }] }, (prevResponses) => {
        const updatedResponses = prevResponses.data.map((r) =>
          responseIdsToUpdate.includes(r.id) ? { ...r, sentiment } : r,
        );
        return { ...prevResponses, data: updatedResponses };
      });
    });
  };

  return {
    getUntaggedResponses,
    getTaggedResponses,
    updateThemesForTagResponses,
    updateResponsesForTagResponses,
    updateResponsesForUntagResponses,
    updateThemesForCreateUpdateTheme,
    updateThemesForDeleteTheme,
    updateSuggestedThemesForCreateSuggestedTheme,
    updateSuggestedThemesForDismissSuggestedTheme,
    updateResponsesForEditSentiment,
  };
}
