import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { UserRoleType } from '../constants';
import useParams from '../hooks/useParams';
import usePermissionedSurveyIds from '../hooks/usePermissionedSurveyIds';
import NotFound from '../pages/NotFound';

// allows access to users who are able to access all surveys
// or who are able to access the current survey
function PermissionedSurveyRoute() {
  const { surveyId } = useParams();
  const { user } = useAuth0();
  const surveyIds = usePermissionedSurveyIds();
  const readAllSurveyRoles = [UserRoleType.ADMINISTRATOR, UserRoleType.EDITOR, UserRoleType.VIEWER];

  if (
    user['https://avalancheinsights.com/roles'].some((value) => readAllSurveyRoles.includes(value))
  ) {
    return <Outlet />;
  }

  return surveyIds.includes(surveyId) ? <Outlet /> : <NotFound />;
}

export default PermissionedSurveyRoute;
