import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const { Text, Title } = Typography;

const StyledSpace = styled(Space)`
  margin-top: calc(100vh * 0.05);
`;

const StyledCloseCircle = styled(CloseCircleFilled)`
  color: #ff4d4f;
  font-size: 70px;
`;

function UploadFailed({ retryUpload }) {
  const navigate = useNavigate();
  return (
    <StyledSpace direction="vertical" align="center" size={25}>
      <StyledCloseCircle />
      <Space direction="vertical" align="center" size={1}>
        <Title level={3}>Upload Failed</Title>
        <Text type="secondary">Something went wrong and your upload could not be completed.</Text>
        <Text type="secondary">Please try to upload the file again.</Text>
      </Space>
      <Space>
        <Button type="primary" onClick={retryUpload}>
          Retry Upload
        </Button>
        <Button onClick={() => navigate('/')}>Go to Survey List</Button>
      </Space>
    </StyledSpace>
  );
}

UploadFailed.propTypes = {
  retryUpload: PropTypes.func.isRequired,
};

export default UploadFailed;
