import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Alert, Button, Modal, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MatomoEvent, QuestionTypeValues } from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import { surveyDetailsType } from '../../../types';
import DraggableQuestion from './DraggableQuestion';
import DroppableCategory from './DroppableCategory';

const { Text, Title } = Typography;

const StyledSpace = styled(Space)`
  padding-bottom: 12px;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  font-weight: bold;
`;

function CategorizeQuestions({ surveyData, setSurveyData, prev, createSurvey }) {
  const { trackEvent } = useMatomo();
  const [activeItem, setActiveItem] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
    useSensor(MouseSensor), // required for testing
  );

  const renameQuestion = ({ updatedName, index }) => {
    const updatedSurveyData = { ...surveyData };
    updatedSurveyData.questions[index].updated_question_name = updatedName;
    setSurveyData(updatedSurveyData);
  };

  const handleUploadSurvey = () => {
    trackEvent(MatomoEvent.UPLOAD_SURVEY_BUTTON_CLICKS);
    const data = new FormData();
    Object.entries(surveyData.surveyDetails).forEach(([key, value]) => {
      if (value) {
        // exclude optional fields that weren't filled
        if (key === 'file') {
          data.append(key, value[0]);
        } else {
          data.append(key, value);
        }
      }
    });

    const questionsMetadata = {};

    surveyData.questions
      .filter((q) => q.columnType !== QuestionTypeValues.REMOVE)
      .forEach((question) => {
        const { index, columnType } = question;
        questionsMetadata[index] = {
          has_themes: columnType === QuestionTypeValues.HAS_THEMES,
          is_filterable:
            columnType === QuestionTypeValues.IS_SINGLE_SELECT ||
            columnType === QuestionTypeValues.IS_MULTI_SELECT,
          is_multi_select: columnType === QuestionTypeValues.IS_MULTI_SELECT,
          updated_question_name: question.updated_question_name,
          is_metadata: columnType === QuestionTypeValues.IS_METADATA,
          is_id_column: columnType === QuestionTypeValues.IS_KEY,
        };
      });

    data.append('questions_metadata', JSON.stringify(questionsMetadata));
    createSurvey.mutate({ data });
  };

  const onFinish = () => {
    if (
      surveyData.questions.filter((question) => question.columnType === QuestionTypeValues.IS_KEY)
        .length > 1
    ) {
      Modal.error({
        title: 'Multiple respondent ids',
        content:
          'Multiple questions/columns were designated as containing respondent ids. Please only select one question/column containing respondent ids',
        okText: 'Back to Question Types',
      });
    } else if (
      surveyData.questions.filter(
        (question) => question.columnType === QuestionTypeValues.HAS_THEMES,
      ).length === 0
    ) {
      Modal.error({
        title: 'Please designate the question(s) you would like us to develop themes for.',
        content:
          'Your file must contain at least one open ended question for which you would like to create themes in order to complete the upload.',
      });
    } else if (
      surveyData.questions.filter(
        (question) =>
          question.columnType === QuestionTypeValues.IS_SINGLE_SELECT ||
          question.columnType === QuestionTypeValues.IS_MULTI_SELECT,
      ).length === 0
    ) {
      Modal.confirm({
        title:
          "It looks like you haven't designated any questions you would like to use as filters.",
        content:
          "Please note that if you don't designate any filters, you won't be able to segment the themes.",
        okText: 'Designate filters',
        cancelText: 'Upload anyways',
        onCancel() {
          handleUploadSurvey();
        },
      });
    } else {
      handleUploadSurvey();
    }
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveItem(active.id);
  };

  const handleDragEnd = (event) => {
    const { over } = event;

    if (!over) {
      setActiveItem(null);
      return;
    }

    const activeQuestion = surveyData.questions.find((item) => item.index === activeItem);

    if (activeQuestion.columnType !== over.id) {
      const updatedQuestions = surveyData.questions.map((question) => {
        if (question.index === activeItem) {
          return {
            ...question,
            columnType: over.id,
          };
        }
        return question;
      });
      const updatedSurveyData = { ...surveyData, questions: updatedQuestions };
      setSurveyData(updatedSurveyData);
    }
    setActiveItem(null);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <Title level={3}>Categorize these columns</Title>
      <StyledSpace direction="vertical" size="large">
        <Space direction="vertical">
          <StyledText>Open-ended Questions</StyledText>
          <Text type="secondary">We&apos;ll analyze these responses for you.</Text>
          <DroppableCategory
            categoryId={QuestionTypeValues.HAS_THEMES}
            questions={surveyData.questions?.filter(
              (q) => q.columnType === QuestionTypeValues.HAS_THEMES,
            )}
            renameQuestion={renameQuestion}
          />
        </Space>
        <Space direction="vertical">
          <StyledText>Single-Select Questions</StyledText>
          <Text type="secondary">You can use these to filter the data later.</Text>
          <DroppableCategory
            categoryId={QuestionTypeValues.IS_SINGLE_SELECT}
            questions={surveyData.questions?.filter(
              (q) => q.columnType === QuestionTypeValues.IS_SINGLE_SELECT,
            )}
            renameQuestion={renameQuestion}
          />
        </Space>
        <Space direction="vertical">
          <StyledText>Multi-Select Questions</StyledText>
          <Text type="secondary">You can use these to filter the data later.</Text>
          <Alert
            message={
              <>
                <b>Note</b>: In your CSV, multi-select choices must be separated using <i>pipes</i>.
              </>
            }
            type="info"
          />
          <DroppableCategory
            categoryId={QuestionTypeValues.IS_MULTI_SELECT}
            questions={surveyData.questions?.filter(
              (q) => q.columnType === QuestionTypeValues.IS_MULTI_SELECT,
            )}
            renameQuestion={renameQuestion}
          />
        </Space>
        <Space direction="vertical">
          <StyledText>ID Column (optional)</StyledText>
          <Text type="secondary">A unique identifier for each respondent.</Text>
          <DroppableCategory
            categoryId={QuestionTypeValues.IS_KEY}
            questions={surveyData.questions?.filter(
              (q) => q.columnType === QuestionTypeValues.IS_KEY,
            )}
            renameQuestion={renameQuestion}
          />
        </Space>
        <Space direction="vertical">
          <StyledText>Other Metadata (optional)</StyledText>
          <Text type="secondary">This will show up as a column in future exports.</Text>
          <DroppableCategory
            categoryId={QuestionTypeValues.IS_METADATA}
            questions={surveyData.questions?.filter(
              (q) => q.columnType === QuestionTypeValues.IS_METADATA,
            )}
            renameQuestion={renameQuestion}
          />
        </Space>
        <Space direction="vertical">
          <StyledText>Ignore</StyledText>
          <Text type="secondary">These columns will be ignored.</Text>
          <DroppableCategory
            categoryId={QuestionTypeValues.REMOVE}
            questions={surveyData.questions?.filter(
              (q) => q.columnType === QuestionTypeValues.REMOVE,
            )}
            renameQuestion={renameQuestion}
          />
        </Space>
      </StyledSpace>
      <Row justify="end">
        <Space>
          <Button onClick={prev} disabled={createSurvey.isLoading}>
            Back
          </Button>
          <Button
            type="primary"
            onClick={onFinish}
            loading={createSurvey.isLoading}
            disabled={createSurvey.isLoading}
            data-cy="finish-upload"
          >
            Finish Upload
          </Button>
        </Space>
      </Row>
      <DragOverlay>
        {activeItem ? (
          <DraggableQuestion
            id={activeItem}
            key={activeItem}
            text={
              surveyData.questions.find((item) => item.index === activeItem).updated_question_name
            }
            renameQuestion={renameQuestion}
            responseCount={
              surveyData.questions.find((item) => item.index === activeItem).responseCount
            }
            popOverResponses={
              surveyData.questions.find((item) => item.index === activeItem).popOverResponses
            }
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}

CategorizeQuestions.propTypes = {
  surveyData: surveyDetailsType.isRequired,
  prev: PropTypes.func.isRequired,
  setSurveyData: PropTypes.func.isRequired,
  createSurvey: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default CategorizeQuestions;
