import { Button, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SortItems } from '../../../../constants';
import useParams from '../../../../hooks/useParams';
import { queryType, questionType, suggestedThemeType } from '../../../../types';
import CreateUpdateThemeGroupModal from '../../CreateUpdateThemeGroupModal';
import ThemeCount from '../../ThemeCount';
import ThemeSearch, { sortOptions } from '../../ThemeSearch';
import CreateUpdateThemeModal from './CreateUpdateThemeModal';
import SuggestedThemeCard from './SuggestedThemeCard';
import ThemeCard from './ThemeCard';
import ThemeGroupCard from './ThemeGroupCard';

const { Title, Text } = Typography;

function ThemesList({
  themes,
  question,
  selectedResponseIds,
  setSelectedRows,
  showResponsesForTheme,
  onDeleteTheme,
  responseQueryFilters,
  suggestedThemes,
  showSuggestedTheme,
  createThemeFromSuggestedTheme,
  dismissSuggestedTheme,
  handleUntagResponses,
}) {
  const { codeFrameId } = useParams();
  const [themesList, setThemesList] = useState([]);
  const [isThemeModalVisible, setThemeModalVisible] = useState(false);
  const [themeToUpdate, setThemeToUpdate] = useState(undefined);
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedThemeId, setSelectedThemeId] = useState(undefined);
  const [filteredThemes, setFilteredThemes] = useState([]);
  const [filteredSuggestedThemes, setFilteredSuggestedThemes] = useState([]);
  const [activeSort, setActiveSort] = useState(SortItems.MOST_PROMINENT);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const list = themes.data
      .filter((t) => !t.parent_theme_id)
      .map((theme) => {
        const children = themes.data.filter((t) => t.parent_theme_id === theme.id);
        return { ...theme, children };
      });
    setThemesList(list);
  }, [themes]);

  useEffect(() => {
    setFilteredThemes(
      themesList
        // filter themes by search term
        .filter(
          (t) =>
            t.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            t.children.some((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase())),
        )
        // sort themes according to the active sort option
        .sort(sortOptions[activeSort].function)
        // filter and sort the children of each theme
        .map((t) => {
          const children = t.children
            .filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .sort(sortOptions[activeSort].function);
          return { ...t, children };
        }),
    );
  }, [themesList, searchTerm, activeSort]);

  useEffect(() => {
    setFilteredSuggestedThemes(
      suggestedThemes
        .filter((t) => t.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort(sortOptions[activeSort].function),
    );
  }, [suggestedThemes, searchTerm, activeSort]);

  const renderThemeCard = (theme) => (
    <ThemeCard
      key={theme.id}
      isSelected={selectedThemeId === `t${theme.id}`}
      theme={theme}
      selectedResponseIds={selectedResponseIds}
      setSelectedRows={setSelectedRows}
      showResponsesForTheme={showResponsesForTheme}
      setSelectedThemeId={setSelectedThemeId}
      openThemeModal={() => {
        setThemeToUpdate(theme);
        setThemeModalVisible(true);
      }}
      responseQueryFilters={responseQueryFilters}
      responseCount={question.num_responses}
      handleUntagResponses={handleUntagResponses}
    />
  );

  // count the number of unique response ids in all themes
  const responsesTagged = new Set(themesList.flatMap((t) => t.response_ids)).size;

  return (
    <Space direction="vertical">
      <Space direction="vertical" size={0}>
        <Space>
          <Title level={4}>Themes</Title>
          <ThemeCount count={themes.data.filter((t) => !t.is_parent).length} />
        </Space>
        <Text type="secondary">
          {responsesTagged}/{question.num_responses} responses tagged
        </Text>
      </Space>
      <Button block onClick={() => setThemeModalVisible(true)}>
        + New Theme
      </Button>
      <ThemeSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeSort={activeSort}
        setActiveSort={setActiveSort}
      />
      {filteredThemes.map((t) =>
        t.is_parent ? (
          <ThemeGroupCard
            key={t.id}
            group={t}
            responseCount={question.num_responses}
            openGroupModal={(e) => {
              e.stopPropagation();
              setSelectedGroup(t);
              setGroupModalVisible(true);
            }}
          >
            {t.children.map((c) => renderThemeCard(c))}
          </ThemeGroupCard>
        ) : (
          renderThemeCard(t)
        ),
      )}
      {filteredSuggestedThemes.map((suggestedTheme) => (
        <SuggestedThemeCard
          key={suggestedTheme.id}
          isSelected={selectedThemeId === `st${suggestedTheme.id}`}
          suggestedTheme={suggestedTheme}
          selectedResponseIds={selectedResponseIds}
          showSuggestedTheme={showSuggestedTheme}
          setSelectedThemeId={setSelectedThemeId}
          responseCount={question.num_responses}
          createThemeFromSuggestedTheme={createThemeFromSuggestedTheme}
          dismissSuggestedTheme={dismissSuggestedTheme}
        />
      ))}
      <CreateUpdateThemeModal
        themes={themes}
        visible={isThemeModalVisible}
        closeModal={() => {
          setThemeModalVisible(false);
          setThemeToUpdate(undefined);
        }}
        selectedTheme={themeToUpdate}
        codeFrameId={codeFrameId}
        selectedResponseIds={selectedResponseIds}
        setSelectedRows={setSelectedRows}
        onDeleteTheme={onDeleteTheme}
      />
      <CreateUpdateThemeGroupModal
        themes={themes}
        visible={isGroupModalVisible}
        closeModal={() => {
          setGroupModalVisible(false);
          setSelectedGroup(undefined);
        }}
        selectedGroup={selectedGroup}
        codeFrameId={codeFrameId}
      />
    </Space>
  );
}

ThemesList.propTypes = {
  themes: queryType.isRequired,
  question: questionType.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  showResponsesForTheme: PropTypes.func.isRequired,
  onDeleteTheme: PropTypes.func.isRequired,
  responseQueryFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  suggestedThemes: PropTypes.arrayOf(suggestedThemeType).isRequired,
  showSuggestedTheme: PropTypes.func.isRequired,
  createThemeFromSuggestedTheme: PropTypes.func.isRequired,
  dismissSuggestedTheme: PropTypes.func.isRequired,
  handleUntagResponses: PropTypes.func.isRequired,
};

export default ThemesList;
