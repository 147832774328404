import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  border: 0;
  padding: 0;
`;

function BackButton({ onClick, text }) {
  return (
    <StyledButton onClick={onClick} icon={<ArrowLeftOutlined />}>
      {text}
    </StyledButton>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default BackButton;
