import { Image, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import InvalidInvitationImage from '../../resources/invalid-invitation.svg';

const { Text, Title } = Typography;

const StyledSpace = styled(Space)`
  margin-top: calc(100vh * 0.25);
`;

function InvalidInvitation() {
  return (
    <StyledSpace direction="vertical" align="center" size={36}>
      <Image src={InvalidInvitationImage} height={100} alt="invalid" />
      <Space direction="vertical" align="center">
        <Title level={3}>Invalid Invitation</Title>
        <Text type="secondary">
          Invitation is invalid, please contact your organization admin for a new invitation.
        </Text>
      </Space>
    </StyledSpace>
  );
}

export default InvalidInvitation;
