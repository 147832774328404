import { Space, Typography } from 'antd';
import React from 'react';
import { GridLoader } from 'react-spinners';
import styled from 'styled-components';
import { BlueDaybreak6 } from '../../styles';

const { Text, Title } = Typography;

const StyledSpace = styled(Space)`
  margin-top: calc(100vh * 0.175);
`;

function SetupLoading() {
  return (
    <StyledSpace direction="vertical" align="center" size={25}>
      <GridLoader color={BlueDaybreak6} />
      <Space direction="vertical" align="center" size={1}>
        <Title level={3}>We&apos;re setting up your account</Title>
        <Text type="secondary">Your account is being created and will be ready soon.</Text>
        <Text type="secondary">Please do not close this window.</Text>
      </Space>
    </StyledSpace>
  );
}

export default SetupLoading;
