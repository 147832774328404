import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Auth0ProviderWithHistory({ children }) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      screen_hint={window.location.pathname === '/signup' ? 'signup' : ''}
      scope="manage_users manage_organization manage_surveys read_all_surveys export_survey_data theme_engine"
    >
      {children}
    </Auth0Provider>
  );
}

Auth0ProviderWithHistory.propTypes = { children: PropTypes.node.isRequired };

export default Auth0ProviderWithHistory;
