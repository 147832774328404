import useFetch from './useFetch';

// eslint-disable-next-line import/prefer-default-export
export function useUpdateSuggestedTheme() {
  const fetcher = useFetch();
  return ({ suggestedThemeId, data }) =>
    fetcher({
      path: `v1/llm/${suggestedThemeId}/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}
