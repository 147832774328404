import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';

const { Text, Title } = Typography;

const StyledSpace = styled(Space)`
  margin-top: calc(100vh * 0.05);
`;

const StyledCheckCircle = styled(CheckCircleFilled)`
  color: #52c41a;
  font-size: 70px;
`;

function UploadSuccessful() {
  const navigate = useNavigate();
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();

  if (organizationLoading) {
    return <Loading />;
  }

  return (
    <StyledSpace direction="vertical" align="center" size={25}>
      <StyledCheckCircle />
      <Space direction="vertical" align="center" size={1}>
        <Title level={3}>Upload Successful</Title>
        {!selfServeEnabled && (
          <>
            <Text type="secondary">We will begin analyzing your responses.</Text>
            <Text type="secondary">Customer Success will reach out within one business day.</Text>
          </>
        )}
      </Space>
      <Button type="primary" onClick={() => navigate('/')}>
        Go to Survey List
      </Button>
    </StyledSpace>
  );
}

export default UploadSuccessful;
