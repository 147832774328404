import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import ResponseOption from './ResponseOption';

function SortableResponseOption({ text }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: text,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ResponseOption
      text={text}
      ref={setNodeRef}
      style={style}
      data-cy="response-option"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...listeners}
    >
      {text}
    </ResponseOption>
  );
}

SortableResponseOption.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SortableResponseOption;
