import { MoreOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Dropdown, message } from 'antd';
import React from 'react';
import { useDeleteInvitation } from '../../api/Invitations';
import SearchableTable from '../../components/SearchableTable';
import { queryType } from '../../types';

function InvitationsTab({ invitations }) {
  const { mutate: deleteInvitation } = useMutation(useDeleteInvitation());
  const queryClient = useQueryClient();

  const handleDeleteInvitation = (invitationId) => {
    deleteInvitation(
      { invitationId },
      {
        onSuccess: () => {
          const remainingInvitations = invitations.data.filter((i) => i.id !== invitationId);
          queryClient.setQueryData(['invitations'], {
            ...invitations,
            data: remainingInvitations,
          });
          message.success('Invitation successfully deleted');
        },
        onError: () => {
          message.error('Something went wrong deleting the invitation');
        },
      },
    );
  };

  const handleCopyInvitation = (token) => {
    navigator.clipboard.writeText(`${window.location.origin}/invitations/${token}`);
    message.success('Invitation link copied');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: 'Status',
      dataIndex: 'is_expired',
      render: (_, record) => (record.is_expired ? 'Expired' : 'Pending'),
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      key: 'action',
      width: 50,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 'Copy',
                label: (
                  <span
                    onClick={() => handleCopyInvitation(record.token)}
                    // onKeyDown/role/tabIndex are needed for accessibility
                    onKeyDown={() => handleCopyInvitation(record.token)}
                    role="menuitem"
                    tabIndex={-1}
                  >
                    Copy Invitation Link
                  </span>
                ),
                disabled: record.is_expired,
              },
              {
                key: 'Delete',
                label: (
                  <span
                    onClick={() => handleDeleteInvitation(record.id)}
                    onKeyDown={() => handleDeleteInvitation(record.id)}
                    role="menuitem"
                    tabIndex={-1}
                  >
                    Delete Invitation
                  </span>
                ),
              },
            ],
          }}
        >
          <Button type="text">
            <MoreOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <SearchableTable
      columns={columns}
      baseData={invitations.data}
      rowKey="id"
      searchPlaceholder="Search invitations..."
      searchColumns={['name']}
    />
  );
}

InvitationsTab.propTypes = {
  invitations: queryType.isRequired,
};

export default InvitationsTab;
