import { useDroppable } from '@dnd-kit/core';
import { Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SortItems, ThemeOrganizationContainer } from '../../../constants';
import { themeType } from '../../../types';
import ThemeCount from '../ThemeCount';
import ThemeSearch, { sortOptions } from '../ThemeSearch';
import DraggableTheme from './DraggableTheme';

const { Title, Text } = Typography;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 232px;
`;

const ThemesDiv = styled.div`
  overflow: auto;
  flex-grow: 1;
  background-color: ${(props) => (props.$isOver ? '#fafafa' : '#fff')};
`;

function UngroupedThemesContainer({ themes, responseCount, searchTerm, setSearchTerm }) {
  const { setNodeRef, isOver } = useDroppable({ id: ThemeOrganizationContainer.UNGROUPED });
  const [activeSort, setActiveSort] = useState(SortItems.MOST_PROMINENT);

  return (
    <StyledDiv>
      <Space direction="vertical" size={0}>
        <Space>
          <ThemeCount count={themes.length} />
          <Title level={4}>{themes.length === 1 ? 'Theme' : 'Themes'} to group</Title>
        </Space>
        <Text>Group themes to add structure to your analysis.</Text>
      </Space>
      <ThemeSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeSort={activeSort}
        setActiveSort={setActiveSort}
      />
      <ThemesDiv ref={setNodeRef} data-cy="ungrouped-themes-container" $isOver={isOver}>
        {themes.length > 0 ? (
          <Space direction="vertical">
            {themes.sort(sortOptions[activeSort].function).map((t) => (
              <DraggableTheme key={t.id} theme={t} responseCount={responseCount} />
            ))}
          </Space>
        ) : (
          <Text type="secondary">No more themes to group</Text>
        )}
      </ThemesDiv>
    </StyledDiv>
  );
}

UngroupedThemesContainer.defaultProps = {
  themes: [],
};

UngroupedThemesContainer.propTypes = {
  themes: PropTypes.arrayOf(themeType),
  responseCount: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default UngroupedThemesContainer;
