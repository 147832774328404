import { MoreOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useCreateInvitation, useDeleteInvitation } from '../../api/Invitations';
import {
  useDeleteGuest,
  useSurveyClients,
  useSurveyInvitations,
  useUpdateGuest,
} from '../../api/Surveys';
import DatePicker from '../../components/DatePicker';

const { Text } = Typography;

function InviteGuestModal({ visible, closeModal, surveyId }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data: clients, isLoading: clientsLoading } = useQuery(
    ['surveyClients', { surveyId }],
    useSurveyClients(),
    { enabled: visible },
  );
  const { data: invitations, isLoading: invitationsLoading } = useQuery(
    ['surveyInvitations', { surveyId }],
    useSurveyInvitations(),
    { enabled: visible },
  );
  const { mutate: createInvitation, isLoading: createInvitationLoading } = useMutation(
    useCreateInvitation(),
  );
  const { mutate: deleteGuest, isLoading: deleteGuestLoading } = useMutation(useDeleteGuest());
  const { mutate: updateGuest, isLoading: updateGuestLoading } = useMutation(useUpdateGuest());

  const { mutate: deleteInvitation, isLoading: deleteInvitationLoading } = useMutation(
    useDeleteInvitation(),
  );

  const handleDeleteGuest = (userId) => {
    deleteGuest(
      { surveyId, userId },
      {
        onSuccess: () => {
          const remainingClients = clients.data.filter((c) => c.id !== userId);
          queryClient.setQueryData(['surveyClients', { surveyId }], {
            ...clients,
            data: remainingClients,
          });
          message.success('Guest successfully removed');
        },
        onError: () => {
          message.error('Something went wrong removing that guest');
        },
      },
    );
  };

  const handleDeleteInvitation = (invitationId) => {
    deleteInvitation(
      { invitationId },
      {
        onSuccess: () => {
          const remainingInvitations = invitations.data.filter((i) => i.id !== invitationId);
          queryClient.setQueryData(['surveyInvitations', { surveyId }], {
            ...invitations,
            data: remainingInvitations,
          });
          message.success('Guest successfully removed');
        },
        onError: () => {
          message.error('Something went wrong removing that guest');
        },
      },
    );
  };

  const handleUpdateGuest = (userId, expirationStr) => {
    updateGuest(
      { userId, surveyId, data: { expires_at: expirationStr } },
      {
        onSuccess: () => {
          const updatedClients = clients.data.map((c) => {
            return c.id === userId ? { expires_at: expirationStr, ...c } : c;
          });
          queryClient.setQueryData(['surveyClients', { surveyId }], {
            ...clients,
            data: updatedClients,
          });
          message.success('Guest successfully updated');
        },
        onError: () => {
          message.error('Something went wrong updating that guest');
        },
      },
    );
  };

  const successDescription = (email) => (
    <>
      Invitation was successfully sent to:
      <br />
      <Text type="secondary">{email}</Text>
    </>
  );

  const onFinish = (formValues) => {
    const data = {
      ...formValues,
      survey_id: surveyId,
    };
    createInvitation(
      { data },
      {
        onSuccess: () => {
          closeModal();
          notification.success({
            message: 'Invitation Sent',
            description: successDescription(formValues.email),
            placement: 'top',
          });
        },
        onError: () => {
          message.error('Something went wrong inviting that user');
        },
      },
    );
  };

  const columns = [
    {
      title: 'Current Guests',
      dataIndex: 'email',
      render: (email, record) => {
        if (record.last_active) {
          return <Text>{email}</Text>;
        }
        return <Text type="secondary">{`${email} (pending)`}</Text>;
      },
    },
    {
      title: 'Last Active',
      dataIndex: 'last_active',
      render: (date) => (date ? dayjs(date).format('ll') : '-'),
    },
    {
      title: 'Expires At',
      dataIndex: 'expires_at',
      render: (_, record) => {
        if (record.expires_at) {
          return (
            <DatePicker
              defaultValue={dayjs(record.expires_at)}
              format="ll"
              bordered={false}
              allowClear={false}
              showToday={false}
              disabledDate={(current) => current.valueOf() < Date.now()}
              onChange={(newDate) => handleUpdateGuest(record.id, newDate.format('YYYY-MM-DD'))}
            />
          );
        }
        return '-';
      },
    },
    {
      key: 'action',
      width: 30,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: record.id,
                label: 'Remove',
              },
            ],
            onClick: () => {
              if (record.last_active) {
                handleDeleteGuest(record.id);
              } else {
                handleDeleteInvitation(record.id);
              }
            },
          }}
        >
          <Button type="text">
            <MoreOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Modal
      title="Manage Guests"
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      onCancel={closeModal}
      okText="Invite"
      confirmLoading={createInvitationLoading}
      destroyOnClose
      // default is 520
      width={775}
      maskClosable={false}
    >
      <Form form={form} autoComplete="off" preserve={false}>
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter a valid email address',
            },
          ]}
        >
          <Input placeholder="Enter email address" />
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={invitations && clients ? invitations.data.concat(clients.data) : []}
        size="small"
        pagination={{
          hideOnSinglePage: true,
        }}
        loading={
          clientsLoading ||
          invitationsLoading ||
          deleteGuestLoading ||
          deleteInvitationLoading ||
          updateGuestLoading
        }
        rowKey="id"
      />
    </Modal>
  );
}

InviteGuestModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default InviteGuestModal;
