import { useDroppable } from '@dnd-kit/core';
import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import DraggableQuestion from './DraggableQuestion';

const { Text } = Typography;

const StyledContainer = styled.div`
  min-height: 40px; // the height of a single card
  max-height: ${(props) => `calc(40px * ${props.questionCount})`};
`;

const StyledEmptyCard = styled(Card)`
  border-radius: 8px;

  .ant-card-body {
    display: none;
  }
`;

function DroppableCategory({ questions, categoryId, renameQuestion }) {
  const { setNodeRef } = useDroppable({
    id: categoryId,
  });

  return (
    <StyledContainer
      ref={setNodeRef}
      data-cy="droppable-container"
      questionCount={questions.length}
    >
      {questions.length > 0 ? (
        questions.map((item) => (
          <DraggableQuestion
            key={item.index}
            id={item.index}
            text={item.updated_question_name}
            renameQuestion={renameQuestion}
            responseCount={item.responseCount}
            popOverResponses={item.popOverResponses}
          />
        ))
      ) : (
        <StyledEmptyCard
          size="small"
          data-cy="empty-card"
          title={<Text type="secondary">None</Text>}
        />
      )}
    </StyledContainer>
  );
}

DroppableCategory.defaultProps = {
  categoryId: undefined,
  questions: [],
};

DroppableCategory.propTypes = {
  categoryId: PropTypes.number,
  questions: PropTypes.arrayOf(Object),
  renameQuestion: PropTypes.func.isRequired,
};

export default DroppableCategory;
