import { Row, Select, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { questionType } from '../../types';

const { Title } = Typography;
const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 100%;
`;

function OpenEndedQuestionsSelect({
  openEndedQuestions,
  selectedQuestionId,
  setSelectedQuestionId,
  extra,
}) {
  return (
    <div>
      <Row justify="space-between" align="top">
        <Title level={5}>Select an open-ended question</Title>
        <Space>{extra}</Space>
      </Row>
      <StyledSelect
        defaultValue={selectedQuestionId}
        value={selectedQuestionId}
        onChange={setSelectedQuestionId}
      >
        {openEndedQuestions.map((q) => (
          <Option key={q.id} value={q.id}>
            {q.text}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
}

OpenEndedQuestionsSelect.defaultProps = {
  selectedQuestionId: undefined,
  extra: null,
};

OpenEndedQuestionsSelect.propTypes = {
  openEndedQuestions: PropTypes.arrayOf(questionType).isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
  extra: PropTypes.arrayOf(PropTypes.element),
};

export default OpenEndedQuestionsSelect;
