import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Modal, Table, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useDeleteSavedSegment,
  useSavedSegments,
  useUpdateSavedSegment,
} from '../../../../api/Surveys';

const { Text } = Typography;

function ManageSavedSegmentsModal({ visible, setVisible, surveyId }) {
  const queryClient = useQueryClient();

  const { data: savedSegments, isLoading: savedSegmentsLoading } = useQuery(
    ['savedSegments', { surveyId }],
    useSavedSegments(),
  );

  const { mutate: deleteSavedSegment, isLoading: deleteSavedSegmentLoading } = useMutation({
    mutationFn: useDeleteSavedSegment(),
    onSettled: async (_, error, variables) => {
      if (error) {
        message.error('Something went wrong deleting segment');
      } else {
        await queryClient.setQueryData(['savedSegments', { surveyId }], (prev) => {
          return { ...prev, data: prev.data.filter((s) => s.id !== variables.segmentId) };
        });
      }
    },
  });

  const { mutate: updateSavedSegment } = useMutation({
    mutationFn: useUpdateSavedSegment(),
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['savedSegments', { surveyId }]);
      const prevSegments = queryClient.getQueryData(['savedSegments', { surveyId }]);
      queryClient.setQueryData(['savedSegments', { surveyId }], (prev) => ({
        ...prev,
        data: prev.data.map((s) =>
          s.id === variables.segmentId ? { ...s, ...variables.data } : s,
        ),
      }));
      return { prevSegments };
    },
    onError: (_, variables, context) => {
      queryClient.setQueryData(['savedSegments', { surveyId }], context.prevSegments);
      message.error('Something went wrong updating segment label');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['savedSegments', { surveyId }]);
    },
  });

  const showConfirmDeleteModal = (segmentId) => {
    Modal.confirm({
      title: 'Are you sure?',
      autoFocusButton: 'ok',
      content:
        "This can't be undone. If you want this segment back, you'll need to create it again.",
      okText: 'Delete',
      onOk: () => deleteSavedSegment({ surveyId, segmentId }),
      okType: 'danger primary',
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      render: (label, record) => (
        <Text
          editable={{
            onChange: (value) =>
              updateSavedSegment({
                surveyId,
                segmentId: record.id,
                data: { label: value },
              }),
          }}
        >
          {label}
        </Text>
      ),
    },
    {
      title: 'Values',
      dataIndex: 'segment',
      render: (values) => Object.values(values).flat().join(', '),
    },
    {
      key: 'delete',
      width: 30,
      render: (_, record) => (
        <Button
          icon={<DeleteOutlined />}
          type="link"
          onClick={() => showConfirmDeleteModal(record.id)}
        />
      ),
    },
  ];

  return (
    <Modal
      title="Manage saved segments"
      open={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      footer={null}
      width={780}
    >
      <Table
        columns={columns}
        dataSource={savedSegments?.data}
        size="small"
        pagination={{
          hideOnSinglePage: true,
        }}
        loading={savedSegmentsLoading || deleteSavedSegmentLoading}
        rowKey="id"
      />
    </Modal>
  );
}

ManageSavedSegmentsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default ManageSavedSegmentsModal;
