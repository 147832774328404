import { Radio, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ChartValueType } from '../../../constants';

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 12px;
`;

function ChartValueTypeToggle({ chartValueType, setChartValueType }) {
  return (
    <Radio.Group
      size="small"
      value={chartValueType}
      onChange={(e) => setChartValueType(e.target.value)}
    >
      <Tooltip title="View absolute values">
        <Radio.Button value={ChartValueType.COUNT}>
          <StyledText italic type="secondary">
            123
          </StyledText>
        </Radio.Button>
      </Tooltip>
      <Tooltip title="View percentage values">
        <Radio.Button value={ChartValueType.PERCENTAGE}>
          <Text type="secondary">%</Text>
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );
}

ChartValueTypeToggle.propTypes = {
  chartValueType: PropTypes.string.isRequired,
  setChartValueType: PropTypes.func.isRequired,
};

export default ChartValueTypeToggle;
