import { Col, Input, Row, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const { Search } = Input;

function SearchableTable({
  baseData,
  searchPlaceholder,
  onSearch,
  afterSearch,
  sortComponents,
  searchValue,
  className,
  searchColumns,
  advancedSearchComponents,
  ...props
}) {
  const [filteredData, setFilteredData] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  // need to store content of search box separately
  // because we don't want to search every time it changes
  const [searchBoxContent, setSearchBoxContent] = useState('');

  useEffect(() => {
    setSearchBoxContent(searchValue);
  }, [searchValue]);

  useEffect(() => {
    const filterData =
      searchColumns.length &&
      baseData?.filter((item) =>
        searchColumns.some((column) =>
          item[column].toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    setFilteredData(filterData || baseData);
  }, [baseData, searchTerm, searchColumns]);

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      if (onSearch) {
        onSearch(searchBoxContent);
      } else {
        setSearchTerm(searchBoxContent);
      }
      if (afterSearch) {
        afterSearch(searchBoxContent);
      }
    }, 300);
    return () => clearTimeout(debouncedSearch);
  }, [searchBoxContent, onSearch, setSearchTerm, afterSearch]);

  return (
    <Space direction="vertical" className={className}>
      <Row align="middle">
        <Col flex="auto">
          <Search
            value={searchBoxContent}
            onChange={(e) => setSearchBoxContent(e.target.value)}
            placeholder={searchPlaceholder}
            allowClear
          />
        </Col>
        {advancedSearchComponents && <Col>{advancedSearchComponents.toggle}</Col>}
        <Col>{sortComponents}</Col>
      </Row>
      {advancedSearchComponents?.collapse}
      <Table
        dataSource={filteredData}
        // we want to be able to use ant design Table props
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Space>
  );
}

SearchableTable.propTypes = {
  baseData: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  afterSearch: PropTypes.func,
  sortComponents: PropTypes.element,
  searchValue: PropTypes.string,
  // We need className so we can create a styled SearchableTable
  className: PropTypes.string,
  searchColumns: PropTypes.arrayOf(PropTypes.string),
  advancedSearchComponents: PropTypes.objectOf(PropTypes.element),
};

SearchableTable.defaultProps = {
  baseData: [],
  onSearch: null,
  afterSearch: null,
  sortComponents: null,
  searchValue: '',
  className: '',
  searchColumns: [],
  advancedSearchComponents: null,
};

export default SearchableTable;
