import Icon, { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SortItems } from '../../constants';
import { ReactComponent as SortMostProminent } from '../../resources/sort_from_highest.svg';
import { ReactComponent as SortLeastProminent } from '../../resources/sort_from_lowest.svg';

const { Search } = Input;

export const sortOptions = {
  [SortItems.MOST_PROMINENT]: {
    icon: <Icon component={SortMostProminent} />,
    function: (a, b) =>
      b.response_ids.length - a.response_ids.length || a.name.localeCompare(b.name),
  },
  [SortItems.LEAST_PROMINENT]: {
    icon: <Icon component={SortLeastProminent} />,
    function: (a, b) =>
      a.response_ids.length - b.response_ids.length || a.name.localeCompare(b.name),
  },
  [SortItems.A_TO_Z]: {
    icon: <SortAscendingOutlined />,
    function: (a, b) => a.name.localeCompare(b.name),
  },
  [SortItems.Z_TO_A]: {
    icon: <SortDescendingOutlined />,
    function: (a, b) => b.name.localeCompare(a.name),
  },
};

function ThemeSearch({ searchTerm, setSearchTerm, activeSort, setActiveSort }) {
  // need to store content of search box separately
  // because we don't want to search every time it changes
  const [searchBoxContent, setSearchBoxContent] = useState('');

  useEffect(() => {
    setSearchBoxContent(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      setSearchTerm(searchBoxContent);
    }, 300);
    return () => clearTimeout(debouncedSearch);
  }, [searchBoxContent, setSearchTerm]);

  const sortComponents = (
    <Dropdown
      menu={{
        items: Object.entries(sortOptions).map(([title, { icon }]) => ({
          key: title,
          label: title,
          icon,
          onClick: () => setActiveSort(title),
        })),
      }}
    >
      <Button type="link" icon={sortOptions[activeSort].icon} />
    </Dropdown>
  );

  return (
    <Row wrap={false}>
      <Col flex="auto">
        <Search
          value={searchBoxContent}
          onChange={(e) => setSearchBoxContent(e.target.value)}
          placeholder="Search themes"
          allowClear
        />
      </Col>
      <Col>{sortComponents}</Col>
    </Row>
  );
}

ThemeSearch.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  activeSort: PropTypes.oneOf(Object.values(SortItems)).isRequired,
  setActiveSort: PropTypes.func.isRequired,
};

export default ThemeSearch;
