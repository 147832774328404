import { useAuth0 } from '@auth0/auth0-react';

export default function useFetch() {
  const { getAccessTokenSilently } = useAuth0();
  const fetcher = async ({ path, method, data, filters, headers }) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/${path}${
        filters ? `?${new URLSearchParams(filters).toString()}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        method,
        body: data,
      },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
  return fetcher;
}
