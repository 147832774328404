import useFetch from './useFetch';

export function useThemes() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { themeIds }] = queryKey;
    return fetcher({ path: `v1/themes/${themeIds}/` });
  };
}

export function useCreateUpdateTheme() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: 'v1/themes/update/',
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useDeleteTheme() {
  const fetcher = useFetch();
  return ({ themeId }) => fetcher({ path: `v1/themes/${themeId}/delete/`, method: 'DELETE' });
}
