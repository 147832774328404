import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Form, Input, Modal, Select, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTagResponses } from '../../../../api/Responses';
import { useCreateUpdateTheme, useDeleteTheme } from '../../../../api/Themes';
import { PhaseType } from '../../../../constants';
import { queryType, themeType } from '../../../../types';
import useUtils from '../../utils';

const { TextArea } = Input;
const { confirm } = Modal;

const StyledForm = styled(Form)`
  .ant-form-item-extra {
    font-size: 12px;
    margin-top: 5px;
  }
`;

function CreateUpdateThemeModal({
  themes,
  visible,
  closeModal,
  selectedTheme,
  codeFrameId,
  selectedResponseIds,
  setSelectedRows,
  onDeleteTheme,
}) {
  const [form] = Form.useForm();
  const themeNameValue = Form.useWatch('name', form);
  const {
    updateResponsesForTagResponses,
    updateResponsesForUntagResponses,
    updateThemesForCreateUpdateTheme,
    updateThemesForDeleteTheme,
  } = useUtils();
  const [updating, setUpdating] = useState(false);

  const createUpdateTheme = useMutation(useCreateUpdateTheme());
  const tagResponses = useMutation(useTagResponses());
  const deleteTheme = useMutation(useDeleteTheme());

  // for merging, show only themes that aren't parents and aren't the currently selected theme
  const themeOptions = themes.data
    .filter((t) => !t.is_parent && t.id !== selectedTheme?.id)
    .sort((a, b) => b.response_ids.length - a.response_ids.length)
    .map((t) => ({ label: t.name, value: t.id }));

  const handleCreateUpdateTheme = async (values) => {
    setUpdating(true);
    const merging = !!values.mergeThemes;
    const creating = !selectedTheme;
    try {
      const response = await createUpdateTheme.mutateAsync({
        data: {
          id: selectedTheme?.id,
          name: values.name,
          description: values.description,
          code_frame_id: codeFrameId,
        },
      });
      const theme = {
        id: response.data.theme_id,
        name: values.name,
        description: values.description,
      };

      let idsToTag = [];
      // if merging, tag all the responses of those themes with this theme
      if (merging) {
        idsToTag = idsToTag.concat(
          themes.data.reduce((acc, t) => {
            if (values.mergeThemes.includes(t.id)) {
              return [...acc, ...t.response_ids];
            }
            return acc;
          }, []),
        );
      }
      // if creating, tag all currently selected responses with this theme
      if (creating) {
        idsToTag = idsToTag.concat(selectedResponseIds);
      }
      // deduplicate the list of ids to tag
      idsToTag = [...new Set(idsToTag)];

      const asyncFns = [];
      if (idsToTag.length) {
        const tagFn = async () => {
          await tagResponses.mutateAsync({
            data: {
              response_ids: idsToTag,
              theme_id: theme.id,
              phase: PhaseType.DISCOVERY,
              code_frame_id: codeFrameId,
              is_theme: true,
            },
          });
          theme.responseIds = idsToTag;
          updateResponsesForTagResponses({ themeId: theme.id, responseIds: theme.responseIds });
        };
        asyncFns.push(tagFn);
      }
      if (merging) {
        values.mergeThemes.forEach((themeId) => {
          asyncFns.push(async () => {
            await deleteTheme.mutateAsync({ themeId });
            updateThemesForDeleteTheme({ themeId });
            updateResponsesForUntagResponses({ themeId });
            onDeleteTheme(themeId);
          });
        });
      }
      await Promise.all(asyncFns.map((fn) => fn()));
      updateThemesForCreateUpdateTheme(theme);
      // if we created a brand new theme, we may be tagging responses
      // if so, untag those responses
      if (creating) {
        setSelectedRows({});
      }
      closeModal();
    } catch {
      message.error('Something went wrong creating or updating theme');
    } finally {
      setUpdating(false);
    }
  };

  const confirmCreateUpdate = (values) => {
    if (themes.data.some((t) => selectedTheme?.id !== t.id && t.name === values.name)) {
      message.error('Sorry, that theme name already exists');
      return;
    }

    // only show a confirmation modal if we're merging themes into the current theme
    if (values.mergeThemes) {
      confirm({
        title: 'Are you sure you want to merge themes?',
        content: "This cannot be undone. If you want a theme back, you'll need to create it again.",
        okText: 'Merge',
        cancelText: 'Go back',
        onOk: () => handleCreateUpdateTheme(values),
      });
    } else {
      handleCreateUpdateTheme(values);
    }
  };

  const handleDeleteTheme = async () => {
    try {
      await deleteTheme.mutateAsync({ themeId: selectedTheme.id });
      updateThemesForDeleteTheme({ themeId: selectedTheme.id });
      updateResponsesForUntagResponses({ themeId: selectedTheme.id });
      onDeleteTheme(selectedTheme.id);
      closeModal();
    } catch {
      message.error('Something went wrong deleting theme');
    }
  };

  const confirmDelete = () => {
    confirm({
      title: 'Are you sure?',
      content: 'This cannot be undone. If you want this theme back, you will need to add it again.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Go back',
      onOk: handleDeleteTheme,
    });
  };

  return (
    <Modal
      title={selectedTheme ? 'Edit Theme' : 'New Theme'}
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(confirmCreateUpdate)
          .catch(() => {});
      }}
      okText={selectedTheme ? 'Save Changes' : 'Create Theme'}
      onCancel={closeModal}
      confirmLoading={updating}
      destroyOnClose
      maskClosable={false}
    >
      <StyledForm layout="vertical" form={form} preserve={false}>
        {!selectedTheme && (
          <Form.Item>
            <Alert
              message={
                <>
                  <b>Eureka!</b> You&apos;ve discovered a theme.
                </>
              }
              type="success"
            />
          </Form.Item>
        )}
        <Form.Item
          label="Theme Name"
          name="name"
          initialValue={selectedTheme?.name}
          rules={[{ required: true, message: 'Add a theme name' }]}
        >
          <Input placeholder="A word or two..." />
        </Form.Item>
        <Form.Item label="Description" name="description" initialValue={selectedTheme?.description}>
          <TextArea placeholder="A phrase or two..." rows={1} />
        </Form.Item>
        <Form.Item
          label="Merge with Other Themes"
          name="mergeThemes"
          extra={`Selected themes will be removed, and their responses will be re-tagged with "${themeNameValue}"`}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Select themes to merge"
            optionFilterProp="label"
            options={themeOptions}
          />
        </Form.Item>
        {selectedTheme && (
          <Form.Item extra="All responses with this theme will be untagged. This cannot be undone.">
            <Button danger onClick={confirmDelete} loading={deleteTheme.isLoading}>
              Delete Theme
            </Button>
          </Form.Item>
        )}
      </StyledForm>
    </Modal>
  );
}

CreateUpdateThemeModal.defaultProps = {
  selectedTheme: undefined,
};

CreateUpdateThemeModal.propTypes = {
  themes: queryType.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedTheme: themeType,
  codeFrameId: PropTypes.number.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  onDeleteTheme: PropTypes.func.isRequired,
};

export default CreateUpdateThemeModal;
