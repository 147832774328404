import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useGroupThemes } from '../../api/CodeFrames';
import { useCreateUpdateTheme, useDeleteTheme } from '../../api/Themes';
import { queryType, themeGroupType } from '../../types/index';
import useUtils from './utils';

const StyledForm = styled(Form)`
  .ant-form-item-extra {
    font-size: 12px;
    margin-top: 5px;
  }
`;

function CreateUpdateThemeGroupModal({
  themes,
  visible,
  closeModal,
  selectedGroup,
  codeFrameId,
  onCreateUpdateGroup,
  onDeleteGroup,
}) {
  const [form] = Form.useForm();
  const { updateThemesForCreateUpdateTheme, updateThemesForDeleteTheme } = useUtils();

  const createUpdateTheme = useMutation(useCreateUpdateTheme());
  const groupThemes = useMutation(useGroupThemes());
  const deleteTheme = useMutation(useDeleteTheme());

  // only show themes that aren't parents and aren't grouped
  // if updating, also show themes that belong to the group
  const themeOptions = themes.data
    .filter((t) => !t.is_parent && (!t.parent_theme_id || t.parent_theme_id === selectedGroup?.id))
    .sort((a, b) => b.response_ids.length - a.response_ids.length)
    .map((t) => ({ label: t.name, value: t.id }));

  const handleCreateUpdateGroup = async (values) => {
    if (themes.data.some((t) => selectedGroup?.id !== t.id && t.name === values.name)) {
      message.error('Sorry, that theme name already exists');
      return;
    }
    try {
      const response = await createUpdateTheme.mutateAsync({
        data: {
          id: selectedGroup?.id,
          name: values.name,
          description: '',
          code_frame_id: codeFrameId,
        },
      });
      const parentThemeId = response.data.theme_id;
      const childThemeIds = values.selectedThemes;
      await groupThemes.mutateAsync({
        codeFrameId,
        data: {
          parent_theme_id: parentThemeId,
          child_theme_ids: childThemeIds,
        },
      });
      onCreateUpdateGroup(parentThemeId, childThemeIds);
      updateThemesForCreateUpdateTheme({
        id: parentThemeId,
        name: values.name,
        childThemeIds,
      });
      closeModal();
      message.success(
        `A group has been ${selectedGroup ? 'updated' : 'created'} with your selected themes`,
      );
    } catch {
      message.error(`Something went wrong ${selectedGroup ? 'updating' : 'creating'} theme group`);
    }
  };

  const handleDeleteGroup = async () => {
    try {
      await deleteTheme.mutateAsync({ themeId: selectedGroup.id });
      onDeleteGroup();
      updateThemesForDeleteTheme({ themeId: selectedGroup.id });
      closeModal();
      message.success('Group has been deleted successfully.');
    } catch {
      message.error('Something went wrong deleting theme group');
    }
  };

  return (
    <Modal
      title={selectedGroup ? 'Edit Group' : 'Create Group'}
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(handleCreateUpdateGroup)
          .catch(() => {});
      }}
      okText={selectedGroup ? 'Save Changes' : 'Create Group'}
      onCancel={closeModal}
      confirmLoading={createUpdateTheme.isLoading || groupThemes.isLoading}
      destroyOnClose
      maskClosable={false}
    >
      <StyledForm layout="vertical" form={form} preserve={false}>
        <Form.Item
          label="Group Name"
          name="name"
          initialValue={selectedGroup?.name}
          rules={[{ required: true, message: 'Add a group name' }]}
        >
          <Input placeholder="Give this group a name" />
        </Form.Item>
        <Form.Item
          label="Add themes to group"
          name="selectedThemes"
          initialValue={themes.data
            .filter((t) => selectedGroup?.id === t.parent_theme_id)
            .map((t) => t.id)}
          rules={[{ required: true, message: 'Please select themes' }]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Select themes to add"
            optionFilterProp="label"
            options={themeOptions}
          />
        </Form.Item>
        {selectedGroup && (
          <Form.Item extra="All subthemes will remain, ungrouped. This cannot be undone.">
            <Button danger onClick={handleDeleteGroup} disabled={deleteTheme.isLoading}>
              Delete Theme Group
            </Button>
          </Form.Item>
        )}
      </StyledForm>
    </Modal>
  );
}

CreateUpdateThemeGroupModal.defaultProps = {
  selectedGroup: undefined,
  onCreateUpdateGroup: () => {},
  onDeleteGroup: () => {},
};

CreateUpdateThemeGroupModal.propTypes = {
  themes: queryType.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedGroup: themeGroupType,
  codeFrameId: PropTypes.number.isRequired,
  onCreateUpdateGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
};

export default CreateUpdateThemeGroupModal;
