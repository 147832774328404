import { EditOutlined } from '@ant-design/icons';
import { useDroppable } from '@dnd-kit/core';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { themeType } from '../../../types';
import DraggableTheme from './DraggableTheme';

const { Paragraph, Text } = Typography;

const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px;
`;

const StyledCard = styled(Card)`
  border-color: #d9d9d9;
  overflow: auto;
  display: flex;
  flex-grow: 1;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
  }
`;

const StyledSpace = styled(Space)`
  flex-grow: 1;
  padding: 8px 0;
  background-color: ${(props) => (props.$isOver ? '#fafafa' : '#fff')};
`;

const Size12Text = styled(Text)`
  font-size: 12px;
`;

const Size16Paragraph = styled(Paragraph)`
  font-size: 16px;

  &.ant-typography {
    margin-bottom: 0px;
  }
`;

function ThemeGroupContainer({ group, childThemes, responseCount, openGroupModal }) {
  const { setNodeRef, isOver } = useDroppable({ id: group.id });
  const groupResponseCount = new Set(childThemes.flatMap((t) => t.response_ids)).size;

  return (
    <Col>
      <StyledDiv>
        <Row wrap={false} data-cy="theme-group-header">
          <Size16Paragraph ellipsis={{ rows: 1, tooltip: true }}>{group.name}</Size16Paragraph>
          <Button onClick={() => openGroupModal(group)} type="link" icon={<EditOutlined />} />
        </Row>
        <StyledCard ref={setNodeRef} $isOver={isOver} data-cy="theme-group-container">
          <Size12Text type="secondary">
            {`${childThemes.length} themes, ${groupResponseCount} responses | ${Math.round(
              (groupResponseCount / responseCount) * 100,
            )}% of full sample`}
          </Size12Text>
          <StyledSpace direction="vertical" $isOver={isOver}>
            {childThemes
              .sort(
                (a, b) =>
                  b.response_ids.length - a.response_ids.length || a.name.localeCompare(b.name),
              )
              .map((ct) => (
                <DraggableTheme key={ct.id} theme={ct} responseCount={responseCount} />
              ))}
          </StyledSpace>
        </StyledCard>
      </StyledDiv>
    </Col>
  );
}

ThemeGroupContainer.defaultProps = {
  childThemes: [],
};

ThemeGroupContainer.propTypes = {
  group: themeType.isRequired,
  childThemes: PropTypes.arrayOf(themeType),
  responseCount: PropTypes.number.isRequired,
  openGroupModal: PropTypes.func.isRequired,
};

export default ThemeGroupContainer;
