import { Collapse, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledUnorderedList = styled.ul`
  margin-bottom: 0;
`;

const StyledResponse = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-family: 'Roboto Mono', monospace;
`;

function AdvancedSearchCollapse() {
  return (
    <Collapse>
      <Panel header="How to use Advanced Search">
        <Space direction="vertical">
          To search for responses...
          <StyledUnorderedList>
            <Space direction="vertical">
              <li>
                <strong>containing multiple words</strong>, separate each word by a space
                <br />
                If you search: <StyledResponse>cat dog mouse</StyledResponse>
                <br />
                This can be a result:{' '}
                <StyledResponse>
                  There was a cat in the room, not a dog. It was chasing a mouse.
                </StyledResponse>
                <br />
                This cannot:{' '}
                <StyledResponse>There was a cat in the room, not a dog.</StyledResponse>
              </li>
              <li>
                <strong>containing an exact phrase</strong>, surround the phrase with quotes
                <br />
                If you search: <StyledResponse>&quot;jumped over the fence&quot;</StyledResponse>
                <br />
                This can be a result:{' '}
                <StyledResponse>I heard something jumped over the fence</StyledResponse>
                <br />
                This cannot: <StyledResponse>I heard something jump over the fence</StyledResponse>
              </li>
              <li>
                <strong>containing one word/phrase or another word/phrase</strong>, separate each
                word/phrase with or
                <br />
                If you search: <StyledResponse>cat or dog</StyledResponse>
                <br />
                This can be a result: <StyledResponse>I see a cat and a mouse</StyledResponse>
                <br />
                This can be a result: <StyledResponse>I see a dog and a mouse</StyledResponse>
                <br />
                This cannot: <StyledResponse>I see a mouse</StyledResponse>
              </li>
              <li>
                <strong>excluding certain words/phrases</strong>, add an - at the start of the
                word/phrase
                <br />
                If you search: <StyledResponse>cat dog -mouse</StyledResponse>
                <br />
                This can be a result: <StyledResponse>I see a cat and dog</StyledResponse>
                <br />
                This cannot: <StyledResponse>I see a cat and dog with a mouse</StyledResponse>
              </li>
            </Space>
          </StyledUnorderedList>
          <div>
            For an even more complex search, you can combine commands:{' '}
            <StyledResponse>&quot;the cat&quot; dog or frog -mouse</StyledResponse>
          </div>
        </Space>
      </Panel>
    </Collapse>
  );
}

export default AdvancedSearchCollapse;
