import { Form, Radio, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

function FormatSelection({ hidden }) {
  return (
    <Form.Item
      label="Select the export format"
      name="format"
      hidden={hidden}
      rules={[{ required: true, message: 'Please select an export format' }]}
      initialValue="csv"
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="csv">CSV</Radio>
          <Radio value="spss">SPSS</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

FormatSelection.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

export default FormatSelection;
