import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React from 'react';

function NavName() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Spin />;
  }

  return isAuthenticated && <span>Welcome, {user.name}</span>;
}

export default NavName;
