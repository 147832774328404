import FusionCharts from 'fusioncharts';

const registerFusionChartTheme = () => {
  FusionCharts.register('theme', {
    name: 'mySampleTheme',
    theme: {
      base: {
        chart: {
          numberSuffix: '%',
          usePlotGradientColor: '0',
          showPlotBorder: '0',
          showShadow: '0',
          showBorder: '0',
          showCanvasBorder: '0',
          bgColor: '#FFFFFF',
          bgAlpha: '100',
          canvasBgAlpha: '0',
          showAlternateHGridColor: '0',
          showAlternateVGridColor: '0',
          divLineAlpha: '100',
          divLineColor: '#E6E6E6',
          divLineThickness: '0',
          numDivLines: '0',
          yAxisValuesPadding: '10',
          labelPadding: '10',
          canvasPadding: '10',
          valuePadding: '0',
          adjustDiv: '1',
          labelDisplay: 'AUTO',
          transposeAxis: '1',
          showCanvasBase: '0',
          tooltipColor: '#333333',
          toolTipBgColor: '#F6F6F6',
          toolTipBgAlpha: '85',
          toolTipPadding: '8',
          toolTipBorderColor: '#999999',
          toolTipBorderRadius: '3',
          toolTipBorderThickness: '1',
          tooltipBorderAlpha: '90',
          showToolTipShadow: '1',
          baseFontSize: '12',
          baseFont: 'Roboto',
          baseFontColor: '#666666',
          outCnvBaseFontSize: '12',
          outCnvBaseFontColor: '#666666',
          captionFontSize: '18',
          captionFontBold: '0',
          captionFontColor: '#333333',
          alignCaptionWithCanvas: '0',
          subCaptionFontSize: '12',
          subCaptionFontBold: '0',
          subCaptionFontColor: '#666666',
          valueFontBold: '1',
          valueFontSize: '12',
          valueFontColor: '#000000',
          placeValuesInside: '0',
          xAxisNameFontBold: '0',
          xAxisNameFontSize: '12',
          xAxisNameFontColor: '#666666',
          yAxisNameFontBold: '0',
          yAxisNameFontSize: '12',
          yAxisNameFontColor: '#666666',
          sYAxisNameFontBold: '0',
          sYAxisNameFontSize: '12',
          sYAxisNameFontColor: '#666666',
          xAxisNamePadding: '8',
          yAxisNamePadding: '8',
          sYAxisNamePadding: '8',
          captionPadding: '10',
          centerLabelFontSize: '12',
          centerLabelColor: '#666666',
          centerLabelBgOval: '1',
          useEllipsesWhenOverflow: '1',
          drawCustomLegendIcon: '1',
          legendShadow: '0',
          legendBorderThickness: '0',
          legendItemFontColor: '#7C7C7C',
          legendItemFontSize: '15',
          alignLegendWithCanvas: '0',
          showHoverEffect: '1',
          plotHoverEffect: '1',
          plotFillHoverAlpha: '95',
          columnHoverAlpha: '95',
          scrollColor: '#808080',
          scrollHeight: '11',
          flatscrollbars: '1',
          scrollshowbuttons: '1',
        },
      },
      bar2d: {
        chart: {
          showXAxisLine: '0',
          paletteColors: '#87E8DE',
          showDivLineValues: '0',
          showZeroPlaneValue: '0',
          showLimits: '0',
          exportEnabled: '1',
        },
      },
      column2d: {
        chart: {
          showXAxisLine: '0',
          paletteColors: '#FAAD14, #FFD666',
          showDivLineValues: '1',
          showZeroPlaneValue: '0',
          showLimits: '0',
          exportEnabled: '1',
        },
      },
      stackedBar2d: {
        chart: {
          showLimits: '0',
          stack100Percent: 1,
        },
      },
      doughnut2d: {
        chart: {
          use3DLighting: '0',
          showPercentValues: '1',
          showValues: '1',
          useDataPlotColorForLabels: '0',
          showLegend: '1',
          legendIconSides: '2',
          showPlotBorder: '0',
          showPercentInTooltip: '0',
        },
      },
    },
  });
};

// eslint-disable-next-line import/prefer-default-export
export const FusionChartTheme = {
  registerFusionChartTheme,
};
