import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, Form, Input, Typography, message } from 'antd';
import React, { useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useOrganizationByName, useSignup } from '../../api/Onboarding';
import SetupLoading from './SetupLoading';

const { Text, Title } = Typography;

const StyledDiv = styled.div`
  max-width: 600px;
`;

const StyledTitle = styled(Title)`
  padding-top: 25px;
  padding-bottom: 10px;
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

function Signup() {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const jwtPayload = useOutletContext();

  const [organizationValidateStatus, setOrganizationValidateStatus] = useState('success');
  const [organizationValidateText, setOrganizationValidateText] = useState('');
  const [promotionalEmailCheckboxStatus, setPromotionalEmailCheckBoxStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getOrganizationByName = useOrganizationByName();
  const { mutateAsync: signupAsync } = useMutation(useSignup());

  const onFinish = async (formValues) => {
    setLoading(true);
    const data = {
      ...formValues,
      auth0_session_token: searchParams.get('session_token'),
      state: searchParams.get('state'),
    };
    try {
      const response = await signupAsync({ data });
      const url = new URL(jwtPayload.continue_uri);
      url.searchParams.set('state', searchParams.get('state'));
      url.searchParams.set('session_token', response.data.session_token);
      window.location.assign(url);
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  };

  const validateOrganizationName = async (value) => {
    if (!value) {
      return { status: 'error', text: 'Organization name is required' };
    }
    const response = await queryClient.fetchQuery(
      ['getOrgByName', { name: value }],
      getOrganizationByName,
    );
    if (response.data.length) {
      return {
        status: 'warning',
        text: (
          <Text type="warning">
            An organization with this name already exists
            <br />
            You can create a new organization or contact your administrator for an invite
          </Text>
        ),
      };
    }
    return { status: 'success', text: '' };
  };

  if (isLoading) {
    return <SetupLoading />;
  }

  return (
    <StyledDiv>
      <StyledTitle level={2}>Welcome to Fathom</StyledTitle>
      <Form layout="vertical" form={form} autoComplete="off" onFinish={onFinish}>
        <Form.Item
          label="Full name"
          name="name"
          initialValue={jwtPayload.name}
          rules={[{ required: true, message: 'Full name is required' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        {!jwtPayload.token && (
          <Form.Item
            label="Organization name"
            name="organization"
            validateTrigger="onBlur"
            validateStatus={organizationValidateStatus}
            help={organizationValidateText}
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  const { status, text } = await validateOrganizationName(value);
                  setOrganizationValidateStatus(status);
                  setOrganizationValidateText(text);
                },
              },
            ]}
          >
            <Input placeholder="Enter organization name" />
          </Form.Item>
        )}
        <Form.Item
          name="allow_promotional_email"
          initialValue={promotionalEmailCheckboxStatus}
          valuePropName="checked"
        >
          <Checkbox
            onChange={(e) => {
              setPromotionalEmailCheckBoxStatus(e.target.checked);
            }}
            checked={promotionalEmailCheckboxStatus}
          >
            <Text>Subscribe me to marketing updates</Text>
            <br />
            <StyledText type="secondary">About 1/month</StyledText>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Complete Sign Up
          </Button>
        </Form.Item>
        <Form.Item name="terms">
          <Text type="secondary">
            By clicking &quot;Complete Sign Up&quot; you agree that you have read Fathom&apos;s{' '}
            <a href="https://www.fathomthat.ai/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            and have reviewed and agree to Fathom&apos;s{' '}
            <a href="https://www.fathomthat.ai/terms-of-service" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            .
          </Text>
        </Form.Item>
      </Form>
    </StyledDiv>
  );
}

export default Signup;
